<template>
  <v-container>
    <v-row class="pb-8">
    </v-row>
    <v-row>
      <v-col
        cols="1">
      </v-col>
      <v-col
        cols="8"
        class="mb-4">
        <span class="text-body-1 mb-3">
          <h2>Allgemeine Geschäftsbedingungen (AGB)</h2>
          <br>
          <br>
          <b>Geltungsbereich</b>
          <br>
          <br>
          Die Dienste auf der Webseite photo-hub.net, im folgenden Photo-Hub genannt, werden von der Monument-Software GmbH
          bereitgestellt. Für diese Dienste gelten die folgenden allgemeinen Geschäftsbedingungen.
          <br>
          <br>
          Vertragssprache ist ausschließlich deutsch. Maßgeblich ist die zum Zeitpunkt des Vertragsabschlusses aktuelle Fassung. Abweichende
          Geschäftsbedingungen des Nutzers finden keine Anwendung, es sei denn, diesen wurden durch die Monument-Software GmbH schriftlich
          oder in Textform zugestimmt.
          <br>
          <br>
          Die Monument-Software GmbH ist berechtigt, die Allgemeinen Geschäftsbedingungen mit Zustimmung des Nutzers zu ändern. Änderungen werden
          dem Benutzer auf Photo-Hub zur Zustimmung vorgelegt. Der Nutzer ist berechtigt, den Vertrag kostenfrei zum nächsten Zahlungszeitraum zu kündigen,
          wenn er geänderten Geschäftsbedingungen nicht zustimmt.
          <br>
          <br>
          <br>
          <b>Vertragsabschluss und Registrierung</b>
          <br>
          <br>
          Ein Vertragsverhältnis zwischen einem Kunden und der Monument-Software GmbH kommt mit einer erfolgreichen Registrierung und Annahme der AGB auf
          Photo-Hub zustande. Der Kunde muss zum Zeitpunkt der Registrierung volljährig sein. Die Registrierung gilt nach dem ersten erfolgreichen Login in
          das System als erfolgreich. Das Vertragsverhältnis läuft auf unbestimmte Zeit.
          <br>
          <br>
          <br>
          <b>Leistungen von Photo-Hub</b>
          <br>
          <br>
          Photo-Hub ist eine Onlineplattform für Fotografen und Foto-Agenturen. Sie ermöglicht es Nutzern, Bilder z.B. per FTP hochzuladen und diese per FTP
          oder andere Protokolle an dritte zu verteilen. Photo-Hub behält sich das Recht vor, die angebotenen Funktionen jederzeit zu verändern, zu verbessern
          und anzupassen. Ein Anspruch auf weitere Funktionalitäten besteht nicht. Photo-Hub wird über das Internet angesprochen, der hierzu benötigte Internetzugang
          gehört nicht zum Leistungsumfang von Photo-Hub. Je nach gewählter Bezahlstufe werden ggf. nur beschränkte Funktionen und Leistungen angeboten. Es besteht
          kein Anspruch auf eine dauerhafte Nutzung von Photo-Hub. Wir behalten uns das Recht vor Änderungen an kostenlos zur Verfügung gestellten Leistungen
          vorzunehmen. Der Umfang der kostenpflichtigen Leistungen ergibt sich aus der zum Zeitpunkt des Vertragsabschlusses jeweils aktuell geltenden Preis- und
          Leistungsbeschreibung.
          <br>
          <br>
          <br>
          <b>Hinweis zum Widerrufsrecht</b>
          <br>
          <br>
          Kunden können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform widerrufen. Die Frist beginnt nach Erhalt der Belehrung in
          Textform, jedoch nicht vor Vertragsabschluss und auch nicht vor Erfüllung unserer Informationspflicht gemäß § 312c Abs. 2 BGB in Verbindung mit § 1 Abs. 1, 2
          und 4 BGB-InfoV. Der Widerruf ist zu richten an info@monument-software.de oder postalisch an Monument-Software GmbH, Joseph-von-Fraunhofer Str. 20, 44227 Dortmund.
          <br>
          <br>
          <br>
          <b>Preise und Zahlungsabwicklung</b>
          <br>
          <br>
          Die Preise für die auf Photo-Hub erbrachten Leistungen ergeben sich aus dem zum Zeitpunkt des Vertragsabschlusses geltenden Preis und Leistungsbeschreibungen. Sofern
          nicht anders angegeben verstehen sich alle Preise als inklusive Umsatzsteuer. Die Vergütung für die auf Photo-Hub bereitgestellten Leistungen wird jeweils zum Anfang
          eines Abrechnungszeitraumes fällig. Die Zahlung erfolgt durch Paypal, der Nutzer muss hierzu im Zahlungsverlauf den Nutzungsbedingungen von Paypal zustimmen. Kommt
          der Nutzer in Zahlungsverzug, ist Photo-Hub berechtigt den Vertrag zum nächsten Abrechnungszeitraum zu kündigen.
          <br>
          <br>
          Rückerstattungen durch Photo-Hub an den Kunden erfolgen sofern möglich stets über den gleichen Zahlungsweg, wie die ursprüngliche Zahlung. Ist dies nicht möglich,
          erfolgt die Zahlung per Überweisung an ein Konto vom Kunden.
          <br>
          <br>
          <br>
          <b>Pflichten der Nutzer</b>
          <br>
          <br>
          Der Nutzer ist verpflichtet Informationen die bei der Account-Erstellung bzw. Änderung angegeben werden, wahrheitsgemäß anzugeben. Zugangsdaten dürfen nicht an
          andere Personen weitergegeben werden. Der Nutzer ist verpflichtet seine Zugangsdaten vor dem Zugriff Dritter zu schützen. Der Nutzer ist verpflichtet, bei der
          Nutzung der zur Verfügung gestellter Dienste die in der Bundesrepublik Deutschland geltenden Gesetzte und Rechtsvorschriften einzuhalten. Dieses gilt auch bei
          Nutzung der Dienste außerhalb Deutschlands.
          <br>
          <br>
          <br>
          <b>Nutzungsrechte</b>
          <br>
          <br>
          Photo-Hub nutzt die vom Nutzer hochgeladenen Fotos ausschließlich um sie wie vom Kunden eingestellt zu modifizieren und an vom Kunden spezifizierte FTP-Server zu
          verteilen. Fotos werden ausdrücklich nicht an Dritte weitergegeben und nur so lange wie technisch nötig von Photo-Hub gespeichert. Alle Nutzungsrechte bleiben
          beim Nutzer. Die Monument-Software GmbH räumt den Nutzern ein einfaches, nicht übertragbares oder lizensierbares Nutzungsrecht an dem Dienst Photo-Hub ein. Photo-Hub
          erlaubt dem Nutzer, den Dienst unter Einhaltung dieser Nutzungsbedingungen und der geltenden Gesetze zu verwenden. Der Nutzer räumt Photo-Hub die Rechte ein,
          die hochgeladenen Bilder zu verarbeiten, zu verändern und nach Kundenkonfiguration weiterzuverteilen. Dies geschieht nur im Zusammenhang mit der technischen
          Umsetzung und Nutzung von Photo-Hub. Mit dem Speichern von Bildern auf Photo-Hub räumt der Nutzer ein unentgeltliches, einfaches, widerrufliches Nutzungsrecht
          auf die Speicherung, Modifikation, Verarbeitung und Verteilung des Werkes ein. Der Nutzer sichert zu im Besitz aller erforderlichen Nutzungs- und Verwertungsrechte
          für die Speicherung, Modifikation, Verarbeitung und Weitergabe des Materials zu sein. Der Nutzer stellt Photo-Hub von jeglicher von ihm zu vertretenden Inanspruchnahme
          durch Dritte einschließlich der durch die Inanspruchnahme ausgelösten Kosten, insbesondere Kosten durch Rechtsverfolgung, frei.
          <br>
          <br>
          Photo-Hub stellt dem Nutzer Möglichkeiten zur Verfügung Bilder an Server Dritter raufzuladen. Diese Server werden vom Nutzer in Photo-Hub mit den notwendigen
          Zugriffsrechten konfiguriert. Der Nutzer sichert zu über die hierzu notwendigen Genehmigungen des jeweiligen Serverbetreibers zu verfügen und die von ihm
          konfigurierten Zugangsdaten nur zu den vom jeweiligen Serverbetreiber angegebenen Bedingungen zu nutzen. Der Nutzer ist verantwortlich für die von Photo-Hub
          an diese Server raufgeladenen Daten ; Photo-Hub lädt ausschließlich Daten auf Aufforderung des Nutzers hoch.
          <br>
          <br>
          <br>
          <b>Verantwortung für die Inhalte</b>
          <br>
          <br>
          Die Verantwortung für alle Fotos und sonstige Materialien die auf Photo-Hub gespeichert und von Photo-Hub verteilt werden, liegt ausschließlich und uneingeschränkt
          beim Nutzer. Photo-Hub kontrolliert die vom Nutzer hochgeladenen Inhalte grundsätzlich nicht. Wir übernehmen daher keine Verantwortung für die Richtigkeit,
          Angemessenheit und Qualität der verteilten Fotos.
          <br>
          <br>
          Pornographische sowie illegale bzw. strafbare Inhalte sind ausdrücklich untersagt. Dem Nutzer ist es verboten Inhalte dieser Kategorien auf Photo-Hub bzw. auf externe
          Server mittels Photo-Hub raufzuladen.
          <br>
          <br>
          <br>
          <b>Laufzeit, Kündigung</b>
          <br>
          <br>
          Der Nutzer kann einen bestehenden Abo Vertrag jederzeit zum Ende des laufenden Abrechnungszeitraums kündigen. Die Laufzeit ergibt sich aus den zum Vertragsabschluss
          gültigen Preis- und Leistungsbeschreibungen. Der Vertrag verlängert sich automatisch um die ursprüngliche Vertragslaufzeit, wenn er nicht binnen der jeweils in der
          Preis- und Leistungsbeschreibung angegebenen Frist gekündigt wird. Die Kündigung kann im Kundenmenü oder per Mail oder Post erfolgen. Ein etwaiges Recht zur
          außerordentlichen Kündigung von Seiten der Monument-Software GmbH bleibt unberührt. Ein solcher Grund liegt insbesondere vor wenn der Dienst missbräuchlich verwendet
          wird oder eine Zahlung nicht rechtzeitig geleistet wird.
          <br>
          <br>
          <br>
          <b>Haftungsbeschränkung</b>
          <br>
          <br>
          Bei Vorsatz oder grober Fahrlässigkeit haftet die Monument-Software GmbH für alle Schäden unbeschränkt. Im übrigen haftet die Monument-Software GmbH nur bei Verletzung einer
          wesentlichen Vertragspflicht. Der Nutzer ist für den Passwort Schutz seiner Daten verantwortlich. Die Monument-Software GmbH übernimmt keine Haftung für
          widerrechtliche Nutzung von Daten aufgrund mangelhaften oder unterbliebenen Passwortschutzes. Zwischen den Kunden des Nutzers und der Monument-Software GmbH
          besteht keinerlei Vertragsbeziehung, deshalb übernehmen wir keine Haftung für die missbräuchliche Verwendung von Photo-Hub zum Hochladen von Fotos auf Server
          Dritter. Die Schadensersatzsumme bei nicht-Vorsatz und nicht grober Fahrlässigkeit ist auf maximal die vom Kunde gezahlten Beträge beschränkt.
          <br>
          <br>
          <br>
          <b>Datenschutz</b>
          <br>
          <br>
          Photo-Hub verwendet nur Server in Deutschland. Die für die Durchführung und Abwicklung erforderlichen personenbezogenen Daten des Nutzers werden nach Maßgabe der
          gesetzlichen Bestimmungen erhoben, verarbeitet und genutzt. Eine Weitergabe dieser Daten findet nicht statt. Ausnahme ist die Abwicklung von Bezahlvorgängen
          und der automatische Versand von Emails an den Nutzer. Für die Abwicklung von Bezahlvorgängen ist die Weitergaben dieser Daten an unser Zahlungsdienstleister
          (Paypal) zwingend erforderlich. Nach Beendigung des Vertragsverhältnisses erfolgt eine Löschung der personenbezogenen Daten,  soweit keine gesetzliche
          Aufbewahrungspflicht besteht. Weitere Informationen zum Datenschutz entnehmen Sie bitte unserer Datenschutzvereinbarung.
          <br>
          <br>
          <br>
          <b>Änderung der Nutzungsbedingungen und Preisänderungen</b>
          <br>
          <br>
          Photo-Hub behält sich Preisänderungen vor. Über eine Änderung der Preise eines bestehenden Abo Vertrags wird der Kunde mindestens 60 im Voraus per E-Mail an
          die vom Kunden im Kundenkonto angegebenen Adresse informiert. Der Kunde kann die Preisänderung ablehnen. Photo-Hub behält sich das Recht vor in diesem Fall den
          Vertrag mit den bisherigen Konditionen weiterlaufen zu lassen. Nimmt Photo-Hub diese Möglichkeit nicht in Anspruch, so entspricht die Ablehnung einer Kündigung
          des Abo Vertrags zum Zeitpunkt der vorgesehenen Preisänderung.
          <br>
          <br>
          Photo-Hub behält sich Änderungen dieser AGB vor. Über eine Änderung der AGB wird der Kunde mindestens 60 im Voraus per E-Mail an die vom Kunden im
          Kundenkonto angegebenen Adresse informiert. Der Kunde kann die Änderung ablehnen. Photo-Hub behält sich das Recht vor in diesem Fall den Vertrag mit
          den bisherigen Konditionen weiterlaufen zu lassen. Nimmt Photo-Hub diese Möglichkeit nicht in Anspruch, so entspricht die Ablehnung einer Kündigung des
          Vertrags zum Zeitpunkt der vorgesehenen AGB Änderung.
          <br>
          <br>
          <br>
          <b>Schlussbestimmung</b>
          <br>
          <br>
          Es gilt das Recht der Bundesrepublik Deutschland. Gerichtstand ist Dortmund. Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein
          oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.
        </span>
      </v-col>
    </v-row>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
};
</script>

<style></style>
