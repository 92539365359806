import moment from 'moment'

export const utils = {
    appReady: false,
    appReadyFuncs: [],

    onAppReady(f) {
        if (this.appReady) {
            f()
        } else {
            this.appReadyFuncs.push(f)
        }
    },

    storeIsReady() {
        console.log('storeIsReady')
        if (this.appReady) {
            return
        }

        this.appReady = true
        this.appReadyFuncs.forEach((f) => {
            f()
        })
    },

    insertSorted(array, value, smaller) {
        var low = 0
        var high = array.length;

        while (low < high) {
            const mid = (low + high) >>> 1
            if (smaller(array[mid], value)) {
                low = mid + 1;
            } else {
                high = mid;
            }
        }
        array.splice(low, 0, value)
    },

    async copyTextToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
      } catch($e) {
      }
    },

    folderById2(parent, itemId) {
        if (parent.type !== 'projects'
            && parent.type !== 'ftpservers') {
            return null
        }

        if (parent.id == itemId) {
            return parent
        }

        for (let folder of parent.children) {
            const item = this.folderById2(folder, itemId)
            if (item !== null) {
                return item
            }
        }

        return null
    },

    folderById(folders, itemId) {
        for (let folder of folders) {
            const item = this.folderById2(folder, itemId)
            if (item !== null) {
                return item
            }
        }

        return null
    },


    formatBytes(count) {
        var out = '';
        if (count < 1024) {
            out = count + 'Byte'
        } else if (count < 1024 * 1024) {
            out = Math.floor((count / 1024)) + ' KB'
        } else if (count < 1024 * 1024 * 1024) {
            out = Math.floor(count / (1024 * 1024)) + ' MB'
        } else {
            out = Math.floor(count / (1024 * 1024 * 1024)) + ' GB'
        }
        return out;
    },

    // timestamp: 2012-12-21 20:40:20
    localizeDate(timestamp) {
        if (!timestamp) {
            return;
        }
        if (timestamp.length < 10) {
            return;
        }

        const year = timestamp.substr(0, 4)
        const month = timestamp.substr(5, 2)
        const day = timestamp.substr(8, 2)
        return String(day).padStart(2, '0') + '.' + String(month).padStart(2, '0') + '.' + String(year).padStart(4, '0')
    },

    // timestamp: 2012-12-21 20:40:20
    localizeTime(timestamp) {
        if (!timestamp) {
            return;
        }
        if (timestamp.length < 16) {
            return;
        }

        const hour = timestamp.substr(11, 2)
        const minute = timestamp.substr(14, 2)

        return String(hour).padStart(2, '0') + ':' + String(minute).padStart(2, '0')
    },

    localizeDateTime(timestamp) {
        return this.localizeDate(timestamp) + '/' + this.localizeTime(timestamp)
    },

    // timestamp: 2024-01-17 11:56:56.957682
    formatTimeIntervalFromNow(timestamp) {
      let date = moment(timestamp, 'YYYY-MM-DD HH:mm:ss')
      let today = new moment()
      var out = ''
      let duration = moment.duration(date.diff(today))
      if (duration.asMinutes() <= 59) {
        out = 'in ' + parseInt(duration.asMinutes()) + ' minutes'
      } else if (today.isSame(date, 'day')) {
        out = 'today, ' + date.format('HH:mm:ss')
      } else {
        out = date.format("YYYY-MM-DD HH:mm:ss")
      }
      return out
    },

    removeObjectFromArray(arr, equalFunc) {
        for (var i = 0; i < arr.length; i++) {
            if (equalFunc(arr[i])) {
                arr.splice(i, 1)
                return true
            }
        }

        return false
    },

    replaceObjectInArray(arr, newObj, equalFunc) {
        for (var i = 0; i < arr.length; i++) {
            if (equalFunc(arr[i])) {
                arr[i] = newObj
                return true
            }
        }

        return false

    },

    pagesAllowedWithoutLogin() {
        return ['landing', 'login', 'signup', 'recoverPassword', 'resetPassword', 'verify', 'imprint', 'termsandconditions', 'privacy'];
    }

}
