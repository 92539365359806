<template>
  <v-container fluid>
    <span class="text-h6">Calendar</span>

    <v-divider class="my-2">
    </v-divider>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary">
    </v-progress-circular>
    <br/>
    <v-row
      justify="center">
      <v-col md="auto" cols="3">
        <v-container fluid>
          <v-row>
            <v-date-picker
              width="200"
              min="2022-01-01"
              max="2030-01-01"
              @change="reloadCalendarEntries"
              v-model="selectedDate">
            </v-date-picker>
            <v-divider>
            </v-divider>
          </v-row>
          <v-row
            v-if="!user.isPhotographer"
            class="mb-0 pb-0">
            <v-col class="my-0 py-0">
              <v-btn
                @click="showExtCalendarList = true"
                class="mx-2 my-0 py-0"
                fab
                small>
                <v-icon>
                    mdi-playlist-edit
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="!user.isPhotographer"
            class="mt-0 pt-0"  >
            <v-col class="mt-0 pt-0">
              <v-list
                style="height:223px;
                overflow-y:auto">
                <v-list-item
                  v-for="calendar in externalCalendars"
                  :key="calendar.dbcalendarid">
                  <v-list-item-action class="ma-1">
                    <v-checkbox
                      v-model="extcalendar[calendar.dbcalendarid]"
                      color="primary"
                      @change="reloadCalendarEntries"
                      >
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content style="display:block">
                    <v-list-item-title>{{calendar.name}}</v-list-item-title>
                    <v-list-item-subtitle>Google Calendar</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="deleteExtCalendarClicked(calendar)"
                        color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="updateCalendarClicked(calendar)"
                        color="green">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="9">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-btn
                @click="newEventClicked()"
                class="mx-2"
                fab
                small>
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              style="max-height:90vh; overflow:auto">
                <v-data-table
                  style="overflow: auto"
                  dense
                  hide-default-footer
                  :headers="headers"
                  :items="calendarEntries"
                  class="elevation-1"
                  group-by="startDate"
                  :items-per-page="200"
                  item-key="dbcalendarentryid">
                  <!-- group row -->
                  <template v-slot:group.header="{items, isOpen, toggle}">
                    <th colspan="5">
                      {{ items[0].localizedStartDate }}
                    </th>
                  </template>

                  <!-- columns -->
                  <template v-slot:item="{ item }">
                    <tr v-on:dblclick="showEventDialog(item, 'edit')">
                      <!-- time -->
                      <td>
                        <strong>{{ item.localizedStartTime }}</strong>

                        <!-- create event button -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="item.calendartype!=='default'"
                              icon
                              @click="showEventDialog(item, 'create')"
                              color="red lighten-2"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-folder-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Create</span>
                        </v-tooltip>

                        <!-- delete event button -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="item.calendartype==='default' && !user.isPhotographer"
                              icon
                              @click="deleteEventClicked(item)"
                              color="red"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete event</span>
                        </v-tooltip>

                        <!-- invite photographer -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="item.calendartype==='default' && !user.isPhotographer"
                              icon
                              @click="invitePhotographersClicked(item)"
                              color="gray"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-account-question</v-icon>
                            </v-btn>
                          </template>
                          <span>Invite Photographer</span>
                        </v-tooltip>


                        <!-- download xmp button -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-show="(user.isOperator || user.isOwner) && item.calendartype==='default'"
                              icon
                              @click="downloadXmp(item)"
                              color="gray"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Download XMP File</span>
                        </v-tooltip>

                        <!-- copy to clipboard -->
                        <v-tooltip
                          top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              @click="copyToClipboard(item)"
                              color="gray"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </template>
                          <span>Copy to Clipboard</span>
                        </v-tooltip>
                      </td>

                      <!-- title -->
                      <td style="user-select: none;">
                        {{ item.summary }}
                      </td>

                      <!-- bookings -->
                      <td>
                        <v-container>
                          <v-row
                            class="mx-0 px-0"
                            >
                            <v-col
                              class="mx-0 px-0"
                              v-for="booking in item.bookings"
                              :key="booking.dbuserid">
                              <v-menu
                                offset-y
                                v-if="user.isOwner || user.isOperator">
                                <template #activator="menu">
                                  <v-tooltip top>
                                    <span v-html="bookingTooltipText(booking)"></span>
                                    <template #activator="tooltip">
                                      <v-chip
                                        style="cursor: pointer;"
                                        v-on="{ ...menu.on, ...tooltip.on }"
                                        v-bind="tooltip.attrs"

                                        :color="bookingTooltipColor(booking)"
                                        class="mx-0 black--text"
                                        label
                                        small
                                        >
                                        {{booking.accreditationNumber}}
                                      </v-chip>
                                    </template>
                                  </v-tooltip>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="item in menuItems(booking)"
                                    :key="item.id"
                                    @click="item.onClick"
                                    link>
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-container>
                      </td>

                      <!-- location -->
                      <td style="user-select: none;">
                        {{ item.location }}
                      </td>

                      <!-- checkboxes -->
                      <td v-if="(user.isOwner || user.isOperator) && item.calendartype==='default'">
                        <div style="display:flex"
                          >
                            <v-tooltip
                              v-for="flag in eventFlags"
                              style="display:inline-block"
                              :key="flag.id"
                              top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-simple-checkbox
                                  style="display:inline-block"

                                  v-model="item.flagDictionary[flag.id]"
                                  v-bind="attrs"
                                  @input="eventFlagChanged(item)"
                                  v-on="on">
                                </v-simple-checkbox>
                              </template>
                              <span>{{flag.title}}</span>
                            </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <!-- new folder message box -->
    <EventDialog
      v-model="eventDialogVisible"
      @ok="saveEvent($event)"
      :eventItem="currentEventItem"
      :title="eventDialogTitle"/>

    <!-- invite to book dialog -->
    <InvitePhotographersDialog
      v-model="showInvitePhotographersDialog"
      :entryIds="[currentEventItem.dbcalendarentryid]"
      />

    <!-- ext calendar list -->
    <AddExtCalendarDialog
      v-model="showExtCalendarList"
      @ok="saveEvent($event)"/>

    <!-- delete event message box -->
    <MessageBox
      v-model="showDeleteEventMessageBox"
      title="Delete event"
      okButtonText="Delete"
      cancelButtonText="Cancel"
      @ok="deleteEntry($event)" />

    <!-- delete ext calendar message box -->
    <MessageBox
      v-model="showRemoveExtCalendarMessageBox"
      title="Remove external calendar"
      okButtonText="Remove"
      cancelButtonText="Cancel"
      @ok="deleteExtCalendar($event)" />

    <!-- confirm event dialog -->
    <ConfirmEventDialog
      v-model="showConfirmEventDialog"
      :calendarEntry="currentEventItem"
      />

    <!-- confirm event dialog -->
    <ConfirmEventDialog
      v-model="showCancelEventDialog"
      :calendarEntry="currentEventItem"
      cancel />

    <!-- updating calendar dialog -->
    <BusyDialog
      v-model="showUpdatingCalendarDialog"
      />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import EventDialog from './EventDialog'
import MessageBox from './MessageBox'
import AddExtCalendarDialog from './AddExtCalendarDialog'
import BusyDialog from './BusyDialog'
import ConfirmDialog from './ConfirmDialog'
import ConfirmEventDialog from './ConfirmEventDialog'
import InvitePhotographersDialog from './InvitePhotographers'
import moment from 'moment'

export default {
  name: 'Calendar',

  mounted() {
    this.$store.dispatch('loadExternalCalendars')
    this.reloadCalendarEntries()

    if (this.user.isOwner
       || this.user.isOperator) {
      this.headers.splice(2, 0, {
        text: 'Bookings',
        value: 'bookings',
        width: '270px',
        sortable: false
      })

      this.headers.push({
        text: 'Checkboxes',
        value: 'checkboxes',
        width: '270px',
        sortable: false
      })
    }
  },

  computed: {
    ...mapState([
      'calendarEntries',
      'externalCalendars',
      'user',
      'team',
      'eventFlags'
    ]),
  },

  watch:{
      $route (to, from){
        if ('id' in to.query) {
          this.$store.dispatch('loadCalendar', to.query.id)
        }
      }
  },

  methods: {
    teamUser(userId) {
      for (let i = 0; i < this.team.users.length; i++) {
        if (this.team.users[i].dbuserid === userId) {
          return this.team.users[i]
        }
      }

      return null
    },

    menuItems(booking) {
      var items = []
      if (booking.bookingState !== 'accepted') {
        items.push({
          id: 'accept',
          title: 'Accept booking',
          onClick: () => {
            booking.bookingState = 'accepted'
            this.$store.dispatch('updateBooking', booking)
          }
        })

        items.push({
          id: 'cancel',
          title: 'Cancel booking',
          onClick: () => {
            booking.bookingState = 'canceled'
            this.$store.dispatch('updateBooking', booking)
          }
        })
      } else {
        const allItems = [
          {
            id: 'notaccredited',
            title: 'Not accredited',
            onClick: () => {
              booking.accreditation = 'notaccredited'
              this.$store.dispatch('updateBooking', booking)
            }
          },

          {
            id: 'requested',
            title: 'Accreditation requested',
            onClick: () => {
              booking.accreditation = 'requested'
              this.$store.dispatch('updateBooking', booking)
            }
          },

          {
            id: 'processing',
            title: 'Processing accreditation ',
            onClick: () => {
              booking.accreditation = 'processing'
              this.$store.dispatch('updateBooking', booking)
            }
          },

          {
            id: 'accredited',
            title: 'Accredited ',
            onClick: () => {
              booking.accreditation = 'accredited'
              this.$store.dispatch('updateBooking', booking)
            }
          },

          {
            id: 'permanently',
            title: 'Permanently accredited ',
            onClick: () => {
              booking.accreditation = 'permanently'
              this.$store.dispatch('updateBooking', booking)
            }
          },

          {
            id: 'cancel',
            title: 'Cancel booking',
            onClick: () => {
              booking.bookingState = 'unset'
              this.$store.dispatch('updateBooking', booking)
            }
          }
        ]

        for (const i in allItems) {
          const item = allItems[i]
          if (item.id !== booking.accreditation) {
            items.push(item)
          }
        }
      }

      return items;
    },

    bookingTooltipText(booking) {
      let tu = this.teamUser(booking.userId)
      if (!tu) {
        return ''
      }
      let text = tu.fullname
      text += '<br />'

      if (booking.bookingState === 'unset') {
        if (booking.suggested === 0) {
          console.error('suggestion count should not be zero if bookingState is unset')
        }

        text += '<b>Invited (' + booking.suggested + 'x)</b>'
      } else if (booking.bookingState === 'rejected' ||
                 booking.bookingState === 'canceled') {
        text += '<b>Canceled</b>'
      } else if (booking.bookingState === 'accepted') {
        if (booking.accreditation === 'notaccredited') {
          text += '<b>Not Accredited</b>'
        } else if (booking.accreditation === 'requested') {
          text += '<b>Accreditation requested</b>'
        } else if (booking.accreditation === 'processing') {
          text += '<b>Processing accreditation</b>'
        } else if (booking.accreditation === 'accredited') {
          text += '<b>Accredited</b>'
        } else if (booking.accreditation === 'permanently') {
          text += '<b>Permanently accredited</b>'
        }
      }

      return text
    },

    bookingTooltipColor(booking) {
      if (booking.bookingState === 'unset') {
        return '#BBDEFB'
      } else if (booking.bookingState === 'rejected' ||
                 booking.bookingState === 'canceled') {
        return '#757575' // gray
      } else if (booking.bookingState === 'accepted') {
        if (booking.accreditation === 'notaccredited') {
          return '#FF3D00' // red
        } else if (booking.accreditation === 'requested') {
          return '#FFEA00' // yellow
        } else if (booking.accreditation === 'processing') {
          return '#FF8F00' // darger orange
        } else if (booking.accreditation === 'accredited') {
          return '#4CAF50' // green
        } else if (booking.accreditation === 'permanently') {
          return '#4CAF50' // green
        }
      }

      return 'f000'
    },

    reloadCalendarEntries() {
      var calendarIds = [ this.$route.query.id ]

      for (var id in this.extcalendar) {
        if (this.extcalendar[id]) {
          calendarIds.push(id)
        }
      }

      const info = {
        calendarIds: calendarIds,
        startDate: this.selectedDate
      }
      this.$store.dispatch('loadCalendarEntries', info)
    },

    clearCurrentEvent() {
      this.currentEventItem.dbcalendarentryid = -1
      this.currentEventItem.date = ''
      this.currentEventItem.time = ''
      this.currentEventItem.calendartype = ''
      this.currentEventItem.startdatetime = ''
      this.currentEventItem.enddatetime = ''
      this.currentEventItem.summary = ''
      this.currentEventItem.description = ''
      this.currentEventItem.category = ''
      this.currentEventItem.subcategory = '';
      this.currentEventItem.location = ''
      this.currentEventItem.city = ''
      this.currentEventItem.state = ''
      this.currentEventItem.country = ''
      this.currentEventItem.team1 = ''
      this.currentEventItem.team2 = ''
    },

    newEventClicked() {
      this.clearCurrentEvent()
      this.currentEventItem.date = moment().format('DD.MM.YYYY')
      this.currentEventItem.time = '17:00'
      this.eventDialogTitle = 'Neuen Event erstellen'
      this.eventDialogVisible = true
    },

    deleteEventClicked(item) {
      this.currentEventItem.dbcalendarentryid = item.dbcalendarentryid
      this.showDeleteEventMessageBox = true
    },

    deleteEntry(event) {
      this.$store.dispatch('deleteCalendarEntry', this.currentEventItem.dbcalendarentryid)
    },

    deleteExtCalendarClicked(calendar) {
      this.currentExtCalendar = calendar
      this.showRemoveExtCalendarMessageBox = true
    },

    confirmEventClicked(item) {
      this.currentEventItem = item
      this.showConfirmEventDialog = true
    },

    cancelEventClicked(item) {
      this.currentEventItem = item
      this.showCancelEventDialog = true
    },

    updateCalendarClicked(calendar) {
      this.showUpdatingCalendarDialog = true
      const data = {
        calendarRefName: calendar.refname
      }
      this.$rest.command('team/command/updateexternalcalendar')
        .data(data)
        .onSuccess((detail) => {
          this.showUpdatingCalendarDialog = false
          this.reloadCalendarEntries()
        })
        .onError((detail) => {
          this.showUpdatingCalendarDialog = false
        })
        .start()
    },

    invitePhotographersClicked(item) {
      this.currentEventItem.dbcalendarentryid = item.dbcalendarentryid
      this.showInvitePhotographersDialog = true
    },

    deleteExtCalendar() {
      this.$rest.delete('team/calendar/' + this.currentExtCalendar.dbcalendarid)
        .onSuccess((json) => {
          this.$store.commit("removeExternalCalendar", this.currentExtCalendar.dbcalendarid)
          this.reloadCalendarEntries()
        })
        .start()
    },

    showEventDialog(fromEvent, mode) {
      if (this.user.isPhotographer) {
        return
      }

      if (mode !== 'create'
          && fromEvent.calendartype !== 'default') {
        return
      }

      // deep copy event
      this.currentEventItem = JSON.parse(JSON.stringify(fromEvent));
      this.currentEventItem.time = this.$utils.localizeTime(fromEvent.startdatetime)
      this.currentEventItem.date = this.$utils.localizeDate(fromEvent.startdatetime)
      if (mode == 'create') {
        this.currentEventItem.dbcalendarentryid = -1
        this.currentEventItem.category = 'sport'
        this.currentEventItem.subcategory = 'football'
        const teams = this.currentEventItem.summary.split('-')
        if (teams.length == 2) {
          this.currentEventItem.team1 = teams[0].trim()
          this.currentEventItem.team2 = teams[1].trim()
          this.currentEventItem.summary = ''
        }

        this.eventDialogTitle = 'Create new event'
      } else if (mode == 'edit') {
        this.currentEventItem.dbcalendarentryid = fromEvent.dbcalendarentryid
        this.eventDialogTitle = 'Edit event'
      } else {
        console.error('unknown mode ' + mode)
      }
      this.eventDialogVisible = true
    },

    editItem (event, {item}) {
    },

    saveEventFlags(event) {
      const data = {
        dbcalendarid: this.$route.query.id,
        entry: event
      }
      this.$store.dispatch('updateCalendarEntry', data)
    },

    saveEvent(event) {
      this.currentEventItem = JSON.parse(JSON.stringify(event));
      this.currentEventItem.startdatetime = event.date + ' ' + event.time
      this.currentEventItem.enddatetime = event.date + ' ' + event.time
      const data = {
        dbcalendarid: this.$route.query.id,
        entry: this.currentEventItem
      }
      if (this.currentEventItem.dbcalendarentryid === -1) {
        this.$store.dispatch('saveNewCalendarEntry', data)
      } else {
        this.$store.dispatch('updateCalendarEntry', data)
      }
    },

    eventFlagChanged(item) {
      // update flags field
      var flagValue = 0
      for (const flagId in item.flagDictionary) {
        if (item.flagDictionary[flagId]) {
          flagValue |= 1 << flagId
        }
      }
      item.flags = flagValue
      this.saveEventFlags(item)
    },

    downloadXmp(item) {
      var ifrm = document.getElementById('downloadFrame');
      var colonAndPort = ''
      if (window.location.port) {
        colonAndPort = ':' + window.location.port
      }
      const url = window.location.protocol + '//' + window.location.hostname + colonAndPort + '/api/' + this.$store.state.team.uuid + '/calendar/xmp/' + item.dbcalendarentryid
      ifrm.src = url
    },

    copyToClipboard(item) {
      const text = item.summary
      this.$utils.copyTextToClipboard(text)
    }
  },

  data: () => ({
    loading: false,
    showDeleteEventMessageBox: false,
    showExtCalendarList: false,
    showConfirmEventDialog: false,
    showCancelEventDialog: false,
    currentExtCalendar: null,
    showRemoveExtCalendarMessageBox: false,
    showUpdatingCalendarDialog: false,
    eventDialogVisible: false,
    showInvitePhotographersDialog: false,
    eventDialogTitle: '',
    selectedDate: '2022-01-01',
    currentEventItem: {
      dbcalendarid: -1,
      dbcalendarentryid: -1,
      date: '',
      time: '',
      calendartype: '',
      startdatetime: '',
      enddatetime: '',
      summary: '',
      description: '',
      category: '',
      location: '',
      city: '',
      state: '',
      country: '',
      bookings: [],
      flags: 0,
      team1: '',
      team2: ''
    },

    extcalendar: {
    },

    headers: [
      {
        text: 'Time',
        value: 'time',
        width: '230px',
        sortable: false
      },
      {
        text: 'Event',
        value: 'summary',
        width: '220px',
        sortable: false
      },
      {
        text: 'Location',
        value: 'location',
        width: '270px',
        sortable: false
      }
     ]
  }),

  components: {
    EventDialog,
    AddExtCalendarDialog,
    MessageBox,
    BusyDialog,
    ConfirmDialog,
    ConfirmEventDialog,
    InvitePhotographersDialog,
}
}
</script>