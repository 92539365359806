<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    max-width="600px">
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>

      <v-card-text
        v-if="message">
        <div class="pt-4">{{message}}</div>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col class="">
            <v-text-field
                dense
                class="mt-6"
                v-if="showEdit"
                v-model="editText"
                :label="editLabelText"
                required>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
            text
            @click="cancelClicked()"
            >
            {{cancelText}}
        </v-btn>

        <v-btn
            text
            @click="okClicked()"
            :disabled="showEdit && !editText"
            >
            {{okText}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean, // show/hide
    title: String,
    message: String,
    cancelButtonText: String,
    okButtonText: String,
    editLabelText: String,
    initialEditText: String
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    showEdit: {
        get() {
            return this.initialEditText
                    || this.editLabelText

        }
    }

  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {
      this.$emit('ok', this.editText)
      this.show = false
    }
  },

  watch: {
    initialEditText: function(newVal) {
      this.editText = newVal
    }
  },

  data() {
    return {
        cancelText: this.cancelButtonText,
        okText: this.okButtonText,
        editText: this.initialEditText
    }
  },
}
</script>