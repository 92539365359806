<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Sign in to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>
    <v-row class="full-height">
      <v-col offset="4" cols="4">
        <v-card class="justify-center pa-2">
          <v-alert
            v-model="invalidlogin"
            type="warning"
            outlined
            dismissible
            >
            Username or password incorrect.
          </v-alert>
          <v-alert
            v-model="emailNotVerified"
            type="warning"
            outlined
            dismissible>
            Please verify your email.
          </v-alert>
          <v-form
            @submit.prevent="login()">
            <v-card-text>
              <v-text-field
                name="login"
                label="Login"
                type="text"
                autocomplete="username"
                v-model="username"
              ></v-text-field>
              <v-text-field
                id="password"
                name="password"
                label="Password"
                type="password"
                autocomplete="current-password"
                v-model="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                :disabled="!login || !password"
                :loading="inProgress"
                >Login</v-btn>
            </v-card-actions>
          </v-form>
          <v-btn
            class="text-right"
            text
            @click="recoverPassword()">
            Forgot password?
          </v-btn>
      </v-card>
      </v-col>
    </v-row>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'Login',

  data: () => ({
    username: '',

    password: '',

    invalidlogin: false,

    emailNotVerified: false,

    inProgress: false
  }),

  methods: {
    recoverPassword() {
      router.push('/recover').catch(() => {})
    },

    login() {
      const data = {
        email: this.username,
        password: this.password,
        rememberme: true
      }

      this.inProgress = true
      this.invalidlogin = false
      this.emailNotVerified = false
      this.$rest.post('user/login')
              .data(data)
              .onError((json) => {
                this.inProgress = false
                if (!('error' in json)) {
                  return false // show global error dialog
                }

                const error = json['error']['code'];
                if (error === 'EmailNotVerified') {
                  this.emailNotVerified = true
                } else {
                  this.invalidlogin = true
                }

                return true
              })
              .onSuccess((json) => {
                this.inProgress = false
                if ('logged_in' in json && json['logged_in'] === true) {
                  this.$store.dispatch('updateUser')
                }
              })
              .start()
    },
  }
};
</script>

<style></style>
