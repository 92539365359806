<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    max-width="600px">
    <v-card>
      <v-card-title>{{cancel ? "Cancel Event" : "Confirm Event"}}</v-card-title>
      <v-divider></v-divider>

      <v-card-text v-if="!cancel">
        <div class="pt-4">Do you want to confirm this event?</div>
      </v-card-text>

      <v-card-text v-if="cancel">
        <div class="pt-4">Do you want to cancel this event?</div>
      </v-card-text>

      <v-form
        :disabled="inProgress"
        @submit.prevent="confirmClicked()"
        v-model="formValid">
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="cancelClicked()"
              >
              No
          </v-btn>

          <v-btn
              text
              type="submit"
              >
              Yes
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean, // show/hide
    editLabelText: String,
    initialEditText: String,
    calendarEntry: [],
    cancel: Boolean
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    confirmClicked() {
      this.inProgress = true
      if (!this.cancel) {
        this.$rest.put('team/calendarentry/' + this.calendarEntry.dbcalendarentryid + '/confirm')
          .onSuccess((json) => {
            this.inProgress = false
            this.$emit('ok')
            this.show = false
            this.$store.commit("confirmCalendarEntry", this.calendarEntry.dbcalendarentryid)
          })
          .start()
      } else {
        this.$rest.put('team/calendarentry/' + this.calendarEntry.dbcalendarentryid + '/cancel')
          .onSuccess((json) => {
            this.inProgress = false
            this.$emit('ok')
            this.show = false
            this.$store.commit("cancelCalendarEntry", this.calendarEntry.dbcalendarentryid)
          })
          .start()
      }
    }
  },

  watch: {
    initialEditText: function(newVal) {
      this.editText = newVal
    }
  },

  data() {
    return {
        editText: this.initialEditText,
        formValid: false,
        inProgress: false
    }
  },
}
</script>