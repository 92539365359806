<template>
  <v-app>
    <v-app-bar
      app
      flat
      clipped-left
      dense>
      <v-toolbar-title style="cursor: pointer" @click="$router.push('/').catch(() => {})">
        PHOTO
        <span style="color: #FF731E">
          HUB
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        v-if="loggedIn"
        offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            class="mr-10"
            color="#FF731E"
            size="32"
            v-bind="attrs"
            v-on="on">
            <span class="white--text text-h7">{{user.initials}}</span>
          </v-avatar>
        </template>
        <v-card>
          <v-list>
            <v-list-item
              @click="$router.push('/userprofile').catch(() => {})">

              <v-list-item-content>
                <v-list-item-title>{{user.fullName}}</v-list-item-title>
                <v-list-item-subtitle>{{team.name}}</v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold">{{user.groups && user.groups.length > 0 ? user.groups[0].localizedName : ''}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$router.push('/imprint').catch(() => {})">
              <v-list-item-content>
                <v-list-item-title>Imprint</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$router.push('/privacy').catch(() => {})">
              <v-list-item-content>
                <v-list-item-title>Privacy</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$router.push('/termsandconditions').catch(() => {})">
              <v-list-item-content>
                <v-list-item-title>Terms and conditions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-card-actions
            v-if="teams.length > 1">
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="changeTeamClicked(); menu=false"
              >
              Team wechseln
            </v-btn>
          </v-card-actions>


          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="logout(); menu=false"
              >
              Logout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>


      <v-btn
        v-if="showSignUpButton"
        color="#FF731E"
        plain
        v-on:click="signIn()">Sign in
      </v-btn>

      <v-btn
        v-if="showSignUpButton"
        outlined
        color="#FF731E"
        v-on:click="signUp()">Sign up
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      ref="navdrawer"
      app
      clipped
      v-if="loggedIn && team.uuid"
      :width="navigation.width"
      permanent>

    <v-treeview
      activatable
      :items="folders"
      item-key="key"
      @update:active="treeItemClicked"
      >
      <template v-slot:label="{ item, open }">
        <v-btn
          v-if="!currentTextEditItem || currentTextEditItem.key !== item.key"
          text
          style="text-transform: none; font-weight:normal"
          class="ml-1 pl-1"
          @contextmenu="currentItem = item; showFolderContextMenu($event, currentItem)"
          @long-press-start="console.log('longpress')">
          <v-icon left color="grey darken-2">
            {{iconType(item, open)}}
          </v-icon>
          {{item.name}}
        </v-btn>

        <v-text-field
          @blur="textFieldBlurred"
          @keydown="textFieldKeyPressed"
          autofocus
          v-if="!!currentTextEditItem && currentTextEditItem.key === item.key"
          v-model="textEditItemText"
          :ref="item.key">
        </v-text-field>

      </template>
    </v-treeview>

    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <iframe id="downloadFrame" style="display:none"></iframe>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer
      v-if="!loggedIn"
      app
      dark
      color="#555"
      class="my-0 py-0">
      <v-container>
      <v-row class="my-1">
        <v-col cols="1">
          <v-divider color="#ccc" style="border-width: 1px !important; width: 35px"></v-divider>
        </v-col>
      </v-row>

      <v-row
        class="my-0 py-0 mx-0">
        <v-col
          cols="3"
          class="my-0 py-0 mx-0 px-0">
          <v-btn
            text
            plain
            class="my-0 py-0 mx-0 px-0"
            @click="$router.push('/imprint').catch(() => {})"
            >Imprint
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        class="my-0 py-0 mx-0">
        <v-col
          cols="3"
          class="my-0 py-0 mx-0 px-0">
          <v-btn
            text
            plain
            class="my-0 py-0 mx-0 px-0"
            @click="$router.push('/privacy').catch(() => {})"
            >Privacy policy
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        class="my-0 py-0 mx-0">
        <v-col
          cols="3"
          class="my-0 py-0 mx-0 px-0">
          <v-btn
            text
            plain
            class="my-0 py-0 mx-0 px-0"
            @click="$router.push('/termsandconditions').catch(() => {})"
            >Terms and conditions
          </v-btn>
        </v-col>
      </v-row>


    </v-container>
    </v-footer>

    <!-- Project context menu-->
    <v-menu
      v-model="showFolderMenu"
      :position-x="x"
      :position-y="y"
      absolute
      bottom
      offset-y
      >
      <v-list>
        <!-- create new project -->
        <v-list-item
          v-if="!!currentItem && currentItem.type === 'projects'"
          @click="newProjectClicked">
          <v-list-item-title>
            Neues Projekt erstellen
          </v-list-item-title>
        </v-list-item>

        <!-- create new ftpserver -->
        <v-list-item
          v-if="!!currentItem && currentItem.type === 'ftpservers'"
          @click="newFtpServerClicked">
          <v-list-item-title>
            Neuen FTP-Server erstellen
          </v-list-item-title>
        </v-list-item>

        <!-- new folder -->
        <v-list-item
          v-if="!!currentItem && (currentItem.type === 'ftpservers' || currentItem.type === 'projects')"
          @click="newFolderClicked">
          <v-list-item-title>
            New folder
          </v-list-item-title>
        </v-list-item>

        <!-- rename folder -->
        <v-list-item
          v-if="!!currentItem && currentItem.parent != -1"
          @click="renameFolderClicked">
          <v-list-item-title>
            Rename
          </v-list-item-title>
        </v-list-item>

        <!-- move item from  -->
        <v-list-item
          v-if="!!currentItem && currentItem.parent != -1"
          @click="moveFolderFromClicked">
          <v-list-item-title>
            Move this item
          </v-list-item-title>
        </v-list-item>

        <!-- move item from  -->
        <v-list-item
          v-if="!!currentItem
                && !!currentMoveItem
                && canPlaceItem"
          @click="moveFolderToClicked">
          <v-list-item-title>
            Place '{{ currentMoveItem.name }}' here
          </v-list-item-title>
        </v-list-item>

        <!-- delete item -->
        <v-list-item
          v-if="!!currentItem && currentItem.parent != -1 && (!currentItem.children || currentItem.children.length === 0)"
          @click="removeItemClicked">
          <v-list-item-title>
            Delete
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- new folder message box -->
    <MessageBox
      v-model="showNewFolderMessageBox"
      title="Neuen Ordner erstellen"
      okButtonText="Erstellen"
      cancelButtonText="Abbrechen"
      editLabelText="Name"
      @ok="createNewFolder($event)" />

    <!-- delete folder message box -->
    <MessageBox
      v-model="showDeleteFolderMessageBox"
      title="Ordner löschen"
      message="Der Ordner wird permanent gelöscht."
      okButtonText="Löschen"
      cancelButtonText="Abbrechen"
      @ok="deleteItem()" />

    <!-- delete project message box -->
    <MessageBox
      v-model="showDeleteProjectMessageBox"
      title="Projekt löschen"
      message="Das Projekt wird permanent gelöscht."
      okButtonText="Löschen"
      cancelButtonText="Abbrechen"
      @ok="deleteItem()" />

    <!-- delete ftp server message box -->
    <MessageBox
      v-model="showDeleteFtpServerMessageBox"
      title="Ftp-Server löschen"
      message="Der Ftp-Server wird permanent gelöscht."
      okButtonText="Löschen"
      cancelButtonText="Abbrechen"
      @ok="deleteItem()" />

    <!-- rename folder -->
    <MessageBox
      v-model="showRenameFolderMessageBox"
      title="Ordner umbenennen"
      :initialEditText="currentItem ? currentItem.name : ''"
      okButtonText="Umbenennen"
      cancelButtonText="Abbrechen"
      @ok="renameFolder($event)" />

    <!-- change team dialog -->
    <ChangeTeamDialog
      v-model="showChangeTeamDialog"
      @ok="changeTeam($event)"
      />

    <!-- error dialog -->
    <BlueScreen
      v-model="showError"
      :message="errorText"
      />

</v-app>
</template>

<script>
import router from './router'
import { mapState } from 'vuex'
import MessageBox from './components/MessageBox'
import BlueScreen from './components/BlueScreen'
import ChangeTeamDialog from './components/ChangeTeamDialog'
import { urlToHttpOptions } from 'url';
export default {
  name: 'App',

  mounted() {
    this.$store.dispatch('updateUser')

    setTimeout(() => {
      this.initNavDrawer()
    }, 500)
  },

  computed: {
    ...mapState({
      loggedIn: (state) => state.loggedIn,
    }),

    ...mapState([
      'folders',
      'user',
      'team',
      'teams',
      'errorText'
    ]),

    showError: {
      get() {
        return this.$store.state.showError;
      },
      set(newValue) {
        return this.$store.dispatch("setShowError", newValue);
      },
    },

    showSignUpButton: function() {
      return !this.loggedIn && this.$route.name !== 'signup' && this.$route.name !== 'verify'
    },

    canPlaceItem() {
      // target must be the same folder type
      const srcParentFolder = this.$utils.folderById(this.folders, this.currentMoveItem.parent)
      if (srcParentFolder.type !== this.currentItem.type) {
        return false
      }

      // makes no sense to place move item to where it already is
      if (this.currentItem.id === this.currentMoveItem.parent) {
        return false
      }

      // cannot place move item into self or its children
      var item = this.currentItem
      while (item !== null) {
        if (item.id === this.currentMoveItem.id) {
          return false
        }
        item =  this.$utils.folderById(this.folders, item.parent)
      }
      return true
    }
  },

  methods: {
    signUp() {
      router.push('/signup').catch(() => {})
    },

    signIn() {
      router.push('/login').catch(() => {})
    },

    commitEditTreeItem() {
      this.currentTextEditItem.name = this.textEditItemText
      if (this.currentTextEditItem.type === 'project') {
        this.$store.dispatch('renameProject', this.currentTextEditItem)
      } else if (this.currentTextEditItem.type === 'ftpserver') {
        this.$store.dispatch('renameFtpServer', this.currentTextEditItem)
      } else {
        // save folder
        this.$store.dispatch('renameFolder', this.currentTextEditItem)
      }
      this.currentTextEditItem = null
    },

    textFieldBlurred() {
      this.commitEditTreeItem()
    },

    textFieldKeyPressed(ev) {
      if (ev.key === 'Escape') {
        this.currentTextEditItem = null
      } else if (ev.key === 'Enter') {
        this.commitEditTreeItem()
      }
    },

    iconType(item, open) {
      switch (item.type) {
        case 'projects':
        case 'ftpservers':
          return open ? 'mdi-folder-open' : 'mdi-folder'
        case 'project':
          return 'mdi-image-multiple'
        case 'ftpserver':
          return 'mdi-database'
      }
    },

    logout() {
      this.$rest.post('user/logout')
        .onSuccess((json) => {
          this.$store.commit("setLoggedOut")
          router.push('/').catch(()=>{})
        })
        .start()
    },

    treeItemClicked(e) {
      if (e.length !== 1) {
        console.error('selected item count should be one')
        return
      }

      let parts = e[0].split('-')
      let type = parts[0]
      let id = parts[1]

      if (type === 'project') {
        // this is a project id
        this.$router.push({ path: '/project', query: { id: id } }).catch(() => {})
      } else if (type === 'ftpserver') {
        this.$router.push({ path: '/ftpserver', query: { id: id } }).catch(() => {})
      } else if (type === 'users') {
        this.$router.push({ path: '/users' }).catch(() => {})
      } else if (type === 'configfiles') {
        this.$router.push({ path: '/configfiles' }).catch(() => {})
      } else if (type === 'calendar') {
        this.$router.push({ path: '/calendar', query: { id: id }}).catch(() => {})
      } else if (type === 'projects'
                 || type === 'ftpservers') {
        this.$router.push({ path: '/folder', query: { id: id }}).catch(() => {})
      }
    },

    showFolderContextMenu(e, parentFolder) {
      e.preventDefault();
      this.currentItem = parentFolder
      this.showFolderMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showFolderMenu = true
      })
    },

    newFolderClicked(e) {
      this.showNewFolderMessageBox = true
    },

    newProjectClicked() {
      this.$router.push({ path: '/newproject',
                          query: { parentfolder: this.currentItem.id } }).catch(() => {})
    },

    newFtpServerClicked() {
      this.$router.push({ path: '/newftpserver',
                        query: { parentfolder: this.currentItem.id } }).catch(() => {})
    },

    renameFolderClicked() {
      this.textEditItemText = this.currentItem.name
      this.currentTextEditItem = this.currentItem
    },

    moveFolderFromClicked() {
      this.currentMoveItem = this.currentItem
    },

    moveFolderToClicked() {
      if (!this.currentMoveItem) {
        return
      }

      if (this.currentMoveItem.type === 'project') {
        const data = {
          'projectId': this.currentMoveItem.id,
          'newFolderId': this.currentItem.id
        }
        this.$store.dispatch('moveProject', data)
      } else if (this.currentMoveItem.type === 'ftpserver') {
        const data = {
          'ftpServerId': this.currentMoveItem.id,
          'newFolderId': this.currentItem.id
        }
        this.$store.dispatch('moveFtpServer', data)
      } else {
        const data = {
          'folderId': this.currentMoveItem.id,
          'newFolderId': this.currentItem.id
        }
        this.$store.dispatch('moveFolder', data)
      }
      this.currentItem = null
      this.currentMoveItem = null
    },

    removeItemClicked() {
      if (this.currentItem.type === 'project') {
        this.showDeleteProjectMessageBox = true
      } else if (this.currentItem.type === 'ftpserver') {
        this.showDeleteFtpServerMessageBox = true
      } else {
        this.showDeleteFolderMessageBox = true
      }
    },

    createNewFolder(folderName) {
      // create the new folder
      const folder = {
        'parentFolderId': this.currentItem.id,
        'name': folderName,
        'type': this.currentItem.type
      }

      this.$store.dispatch('newFolder', folder)

      this.$nextTick(() => {
        this.currentItem = null
      });
    },

    deleteItem() {
      if (this.currentItem.type === 'project') {
        this.$store.dispatch('deleteProject', this.currentItem.id)
      } else if (this.currentItem.type === 'ftpserver') {
        this.$store.dispatch('deleteFtpServer', this.currentItem.id)
      } else {
        this.$store.dispatch('deleteFolder', this.currentItem.id)
      }

      this.currentItem = null
    },

    renameFolder(newName) {
      this.currentItem.newName = newName
      this.$store.dispatch('renameFolder', this.currentItem)
      this.$nextTick(() => {
        this.currentItem = null
      });
    },

    setNavDrawerWidth() {
      let drawer = this.$refs.navdrawer.$el.querySelector(
        ".v-navigation-drawer__border"
      )
      drawer.style.width = this.navigation.borderSize + "px"
      drawer.style.cursor = "ew-resize"
    },

    changeTeamClicked() {
      this.showChangeTeamDialog = true
    },

    changeTeam(event) {

    },

    initNavDrawer() {
      if (!this.$refs.navdrawer) {
        setTimeout( () => this.initNavDrawer(), 500)
        return
      }

      this.setNavDrawerWidth()
      this.setEvents()
      this.navDrawerInitialized = true
    },

    setEvents() {
      const minSize = this.navigation.borderSize
      const el = this.$refs.navdrawer.$el
      const drawerBorder = el.querySelector(".v-navigation-drawer__border")
      const vm = this;

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        var width = e.clientX
        if (width < 120) {
          width = 120
        } else if (width > 400) {
          width = 400
        }
        el.style.width = width + "px"
        e.preventDefault()
      }

      drawerBorder.addEventListener("mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial"
            document.addEventListener("mousemove", resize, false)
          }
          e.preventDefault()
        },
        false
      )

      document.addEventListener(
        "mouseup",
        (e) => {
          el.style.transition = ""
          this.navigation.width = el.style.width
          document.body.style.cursor = ""
          document.removeEventListener("mousemove", resize, false)
          e.preventDefault()
        },
        false
      )
    }

  },

  data: () => ({
    currentItem: null,
    currentTextEditItem: null,
    textEditItemText: 'Herbert',
    currentMoveItem: null,
    showFolderMenu: false,
    x: 0,
    y: 0,
    showNewFolderMessageBox: false,
    showDeleteFolderMessageBox: false,
    showDeleteProjectMessageBox: false,
    showDeleteFtpServerMessageBox: false,
    showRenameFolderMessageBox: false,
    showChangeTeamDialog: false,
    navigation: {
      width: 200,
      borderSize: 3
    },

    navDrawerInitialized: false
  }),

  components: {
    MessageBox,
    ChangeTeamDialog,
    BlueScreen
  }
};
</script>
