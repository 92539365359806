<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>
    <v-row class="full-height">
      <v-col offset="4" cols="4">
      <v-alert
        type="warning"
        outlined
        >
        You are currently not a member of a team.
      </v-alert>
      </v-col>
    </v-row>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'NoTeam',

  data: () => ({
  }),

  methods: {
  }
};
</script>

<style></style>
