<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>

    <v-row
      v-if="!passwordSent"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Enter a new password and click send.
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="passwordSent"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Success. Please go to the login page.
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="showError">
      <v-col>
        <v-alert
          outlined
          type="warning"
          prominent
          >
          {{errorMessage}}
        </v-alert>
      </v-col>
    </v-row>

    <v-form
      v-if="!passwordSent"
      :disabled="inProgress"
      @submit.prevent="sendClicked()"
      v-model="formValid">

      <v-row
        v-if="!passwordSent"
        class="full-height">
        <v-col offset="4" cols="4">
          <v-card class="justify-center pa-2">
            <v-card-text>
                <!-- password -->
                <v-text-field
                  id="password"
                  v-model="password"
                  name="password"
                  label="Password"
                  type="password"
                  autocomplete="new-password"
                  :rules="[rules.required, rules.counter]"
                >
                </v-text-field>

                <!-- password 2 -->
                <v-text-field
                  id="password2"
                  v-model="password2"
                  name="password2"
                  label="Repeat password"
                  type="password"
                  autocomplete="new-password"
                  :rules="[rules.required, rules.counter, rules.password2]"
                >
                </v-text-field>

              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="inProgress"
                  :disabled="!formValid"
                  >Send</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'Signup',

  mounted() {
    if ('token' in this.$route.query) {
      this.token = this.$route.query.token
      this.selector = this.$route.query.selector
    }
  },

  methods: {
    sendClicked() {
      var data = {
        'token': this.token,
        'selector': this.selector,
        'password': this.password
      }

      this.inProgress = true
      this.showError = false
      this.$rest.put('/user/resetPassword')
        .data(data)
        .onSuccess((json) => {
          this.inProgress = false
          if ('logged_in' in json) {
            this.passwordSent = true
          } else {
            this.showError = true
            this.errorMessage = 'Something went WRONG!'
          }
          this.inProgress = false
        })
        .start()
    },
  },

  data() {
    return {
        token: null,

        selector: null,

        formValid: false,

        password: '',

        password2: '',

        inProgress: false,

        passwordSent: false,

        showError: false,

        errorMessage: '',

        rules: {
          required: value => !!value || 'Required.',

          counter: value => value.length >= 5 || 'At least 5 characters',

          password2: (value) => {
            if (this.password && value === this.password) {
              return true
            }
            return 'Passwords are not equal'
          }
        }
    }
  }
};
</script>

<style></style>
