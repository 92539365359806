<template>
  <v-dialog
    content-class="elevation-0"
    transition="false"
    persistent
    v-model="show"
    max-width="600px">
    <v-card
      class="ma-4"
      style="background:#0001ab"
      >
      <v-card-text>
        <v-row>
          <v-col class="" style="color: white; font-family: 'Lucida Console', monospace;">
            <span>A problem has been detected and the app has been shut down. You need to reload the page.</span>
            <br/>
            <br/>
            <span>Detail: {{message}}</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
            style="color: white; font-family: 'Lucida Console', monospace;"
            text
            @click="reloadClicked()"
            >
            Reload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean, // show/hide
    message: String,
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    showEdit: {
        get() {
            return this.initialEditText
                    || this.editLabelText

        }
    }
  },

  methods: {
    reloadClicked() {
      location.reload()
    }
  },

  watch: {
    initialEditText: function(newVal) {
      this.editText = newVal
    }
  },

  data() {
    return {
    }
  },
}
</script>