<template>
  <v-container
    fluid>
    <span class="text-h6">Project</span>
    <v-divider class="my-2">
    </v-divider>

    <v-progress-circular
      v-if="loadingProject"
      indeterminate
      color="primary">
    </v-progress-circular>
    <br/>

    <v-container
      fluid
      v-if="!loadingProject">
      <v-tabs
        icons-and-text
        v-model="activetab">
        <v-tab
          v-on:click="$router.push({ path: 'overview', query: $route.query }).catch(()=>{});"
          >
          Overview
          <v-icon>mdi-eye-outline</v-icon>
        </v-tab>
        <v-tab
          v-on:click="$router.push({path: 'destinations', query: $route.query}).catch(()=>{})"
          >
          Destinations
          <v-icon>mdi-car-shift-pattern</v-icon>
        </v-tab>
        <v-tab
          v-on:click="$router.push({path: 'metadata', query: $route.query}).catch(()=>{})"
          >
          Metadata
          <v-icon>mdi-database</v-icon>
        </v-tab>
        <v-tab
          v-on:click="$router.push({path: 'script', query: $route.query}).catch(()=>{})"
          >
          Script
          <v-icon>mdi-script-text-outline</v-icon>
        </v-tab>
        <v-tab
          v-on:click="$router.push({path: 'protocol', query: $route.query}).catch(()=>{})"
          >
          Protocol
          <v-icon>mdi-text-box-multiple-outline</v-icon>
        </v-tab>
      </v-tabs>
      <br/>
      <router-view
        v-on:saveProject="save"
        v-on:reloadProject="reload"
        >
      </router-view>
    </v-container>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ProjectBase',

  mounted() {
    if ('id' in this.$route.query) {
      this.$store.dispatch('loadProject', this.$route.query.id)
      this.projectId = this.$route.query.id
    }
  },

  data: () => ({
    activetab: 0,
    projectId: null
  }),

  watch:{
      $route (to, from){
        if ('id' in to.query) {
          if (to.query.id !== this.projectId) {
            this.$store.dispatch('loadProject', to.query.id)
            this.activetab = 0
            this.projectId = to.query.id
          }
        }
      }
  },

  computed: {
    ...mapState([
      'loadingProject',
      'project'
    ])
  },

  methods: {
    reload() {
      this.$rest.get('team/project/' + this.$route.query.id)
                .onSuccess((json) => {
                  this.$store.commit('setProject', json['project'])
                })
                .start()
    },

    save() {
      if (!this.project.canWrite) {
        return
      }

      this.$rest.put('team/project/' + this.project.projectid)
                .data(this.project)
                .onSuccess((json) => {
                })
                .start()
    }

  }
}
</script>
