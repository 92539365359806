<template>
  <v-container
    fluid>
    <span class="text-h6">FTP-Server</span>

    <v-divider class="my-2">
    </v-divider>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary">
    </v-progress-circular>
    <br/>

    <v-form
      :disabled="!ftpServer.canWrite"
      v-if="!loading">
      <v-row>
        <v-col>
          <span class="text-h5">
            Required fields
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-text-field
            dense
            label="Name"
            maxLength="64"
            v-model="ftpServer.name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-text-field
            dense
            label="Adresse"
            maxLength="256"
            v-model="ftpServer.host"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-text-field
            dense
            label="User"
            autocomplete="off"
            maxLength="256"
            v-model="ftpServer.username"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-text-field
            type="password"
            label="Passwort"
            autocomplete="new-password"
            maxLength="256"
            v-model="ftpServer.password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-select
            label="Protocol"
            :items="protocols"
            item-text="text"
            item-value="value"
            v-model="ftpServer.protocol"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="text-h5">
            Optional fields
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="3">
          <v-text-field
            dense
            label="Path"
            maxLength="256"
            v-model="ftpServer.path"
            >
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3">
          <v-text-field
            label="Port"
            maxLength="256"
            v-model="ftpServer.port"
            :rules="[rules.port]"
            >
        ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="showFtpTestResult">
        <v-col
        cols="3">
          <v-alert
            v-model="showFtpTestResult"
            :type="ftpTestResultOk ? 'success':'warning'"
            outlined
            dismissible
            >
            {{ftpTestResult}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col
        cols="3">
          <v-btn
            :disabled="!isFormValid"
            @click="onTestConnectionClicked()"
            :loading="!!testingFtpServer"
            >
            Verbindung testen
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-btn
            class="mt-6"
            color="primary"
            v-on:click="onSaveClicked()"
            :disabled="!ftpServer.canWrite || !isFormValid"
            >{{'id' in $route.query ? 'Speichern' : 'FTP-Server anlegen'}}
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            class="mt-6 ml-6"
            color="error"
            v-on:click="onDeleteClicked()"
            :disabled="!ftpServer.canWrite"
            v-if="'id' in $route.query"
            >Löschen
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>

export default {
  name: 'FTPServer',

  components: {
    ConfirmDialog: () => import("./ConfirmDialog"),
  },

  mounted() {
    if ('id' in this.$route.query) {
      this.loading = true;
      this.$rest.get('team/ftpserver/' + this.$route.query.id)
                .onSuccess((json) => {
                  this.ftpServer = json['ftpserver']
                  this.loading = false
                })
                .start()
    }
  },

  watch:{
      $route (to, from){
        this.testingFtpServer = false
        this.showFtpTestResult = false
        this.loading = false
        this.ftpTestResult = false
        this.ftpTestResultOk = false,
        this.ftpServer = {
          name: '',
          host: '',
          port: '',
          path: '',
          protocol: 'ftp',
          username: '',
          password: '',
          canWrite: true,
          parentFolderId: null
        }
        if ('id' in this.$route.query) {
          this.loading = true;
          this.$rest.get('team/ftpserver/' + to.query.id)
                    .onSuccess((json) => {
                      this.ftpServer = json['ftpserver']
                      this.loading = false
                    })
                    .start()
        }
      }
  },

  data: () => ({
    loading: false,

    testingFtpServer: null,

    showFtpTestResult: false,

    ftpTestResult: '',

    ftpTestResultOk: false,

    ftpServer: {
      name: '',
      host: '',
      port: '',
      path: '',
      protocol: 'ftp',
      username: '',
      password: '',
      canWrite: true,
      parentFolderId: null
    },

    protocols: [
      {
        text: 'FTP (File Transfer Protocol)',
        value: 'ftp'
      },
      {
        text: 'SFTP (Secure File Transfer Protocol)',
        value: 'sftp'
      }
    ],

    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      port: value => {
        if (!value) {
          return true
        }

        let isnum = /^\d+$/.test(value)
        if (!isnum) {
          return "Port must be between 1 and 65535"
        }

        const ival = parseInt(value)
        if (ival === NaN) {
          return "Port must be between 1 and 65535"
        }

        if (ival < 1
            || ival > 65535) {
          return "Port must be between 1 and 65535"
        }

        return true
      },
    },

  }),

  computed: {
    isFormValid() {
      if (!this.ftpServer.name
          || !this.ftpServer.name.trim()) {
        return false
      }

      if (!this.ftpServer.host) {
        return false
      }

      if (!this.ftpServer.username) {
        return false
      }

      if (!this.ftpServer.password) {
        return false
      }

      return true
    },
  },

  methods: {

    save() {
      if ('ftpserverid' in this.ftpServer) {
        this.$rest.put('team/ftpserver/' + this.ftpServer.ftpserverid)
                  .data(this.ftpServer)
                  .onSuccess((json) => {
                    this.$router.push({ path: '/team/ftpservers' }).catch(() => {})
                  })
                  .start()
      } else {
        this.ftpServer.parentFolderId = this.$route.query.parentfolder
        this.$rest.post('team/ftpserver')
                  .data(this.ftpServer)
                  .onSuccess((json) => {
                    if (json['result'] === 'OK') {
                      const ftpServerId = json['ftpserverid']
                      this.ftpServer.id = parseInt(ftpServerId)
                      this.$store.dispatch('addFtpServer', this.ftpServer)
                      this.$router.push({ path: '/team/ftpservers' }).catch(() => {})
                    }
                  })
                  .start()
      }
    },

    delete() {
      this.$rest.delete('team/ftpserver/' + this.ftpServer.ftpserverid)
                .onSuccess((json) => {
                  this.$router.push({ path: '/team/ftpservers'}).catch(() => {})
                })
                .start()
    },

    onSaveClicked() {
      this.save()
    },

    onTestConnectionClicked() {
      let data = {
        host: this.ftpServer.host,
        port: this.ftpServer.port,
        username: this.ftpServer.username,
        password: this.ftpServer.password,
        path: this.ftpServer.path,
        protocol: this.ftpServer.protocol
      }

      this.showFtpTestResult = false
      this.testingFtpServer = true
      this.$rest.command('team/command/testftpserver')
        .data(data)
        .onSuccess((detail) => {
          this.testingFtpServer = false
          this.showFtpTestResult = true
          this.ftpTestResult = 'Success!!!'
          this.ftpTestResultOk = true
        })
        .onError((detail) => {
          this.testingFtpServer = false
          this.showFtpTestResult = true
          this.ftpTestResult = detail
          this.ftpTestResultOk = false
        })
        .start()
    },

    async onDeleteClicked() {
      if (await this.$refs.confirm.open(
            "Confirm",
            "Möchten Sie den FTP-Server wirklich löschen?"
          )
        ) {
          this.delete();
        }
    },
  }
}
</script>
