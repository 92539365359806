import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '../components/Welcome.vue'
import Project from '../components/Project.vue'
import ProjectOverview from '../components/ProjectOverview'
import ProjectDestinations from '../components/ProjectDestinations'
import ProjectMetaData from '../components/ProjectMetaData'
import ProjectScript from '../components/ProjectScript'
import ProjectProtocol from '../components/ProjectProtocol'
import NewProject from '../components/NewProject'
import FTPServer from '../components/FTPServer'
import Calendar from '../components/Calendar'
import Login from '../components/Login'
import Landing from '../components/Landing'
import Imprint from '../components/Imprint'
import TermsAndConditions from '../components/TermsAndConditions'
import Privacy from '../components/Privacy'
import NoTeam from '../components/NoTeam'
import TeamNotReady from '../components/TeamNotReady'
import SignUp from '../components/SignUp'
import RecoverPassword from '../components/RecoverPassword'
import ResetPassword from '../components/ResetPassword'
import Users from '../components/Users'
import ConfigFiles from '../components/ConfigFiles'
import VerifyEmail from '../components/VerifyEmail'
import AcceptPhotographerInvitation from '../components/AcceptPhotographerInvitation'
import UserProfile from '../components/UserProfile'
import Folder from '../components/Folder'
import Store from '../store'

Vue.use(VueRouter)

const routes = [
  { // this is the landing page for logged out users
    path: '/',
    name: 'landing',
    component: Landing
  },
  { // this is the default page after login
    path: '/welcome',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/noteam',
    name: 'noTeam',
    component: NoTeam
  },
  {
    path: '/teamnotready',
    name: 'teamNotReady',
    component: TeamNotReady
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyEmail
  },
  {
    path: '/recover',
    name: 'recoverPassword',
    component: RecoverPassword
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/folder',
    name: 'folder',
    component: Folder
  },
  {
    path: '/ftpserver',
    alias: '/newftpserver',
    name: 'ftpserver',
    component: FTPServer
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/configfiles',
    name: 'configFiles',
    component: ConfigFiles
  },
  {
    path: '/newproject',
    name: 'newProject',
    component: NewProject
  },
  {
    path: '/acceptPhotographerInivitation',
    name: 'acceptPhotographerInvitation',
    component: AcceptPhotographerInvitation
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: UserProfile
  },
  {
    path: '/project',
    name: 'project',
    component: Project,
    children: [
      {
        path: 'overview',
        name: 'projectOverview',
        component: ProjectOverview,
        props: true
      },
      {
        path: 'destinations',
        component: ProjectDestinations,
        props: true
      },
      {
        path: 'metadata',
        component: ProjectMetaData,
        props: true
      },
      {
        path: 'script',
        component: ProjectScript,
        props: true
      },
      {
        path: 'protocol',
        component: ProjectProtocol,
        props: true
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if ('debug' in to.query) {
    let debug = to.query['debug']
    if (debug === '1' || debug === 'true') {
      router.app.$store.commit("enableDebugMode")
    } else if (debug === '0' || debug === 'false') {
      router.app.$store.commit("disableDebugMode")
    }
  }

  console.log('to ' + to.name + ' loggedIn=' + router.app.$store.state.loggedIn + ' team=' + router.app.$store.state.team.uuid)

  if (!router.app.$store.state.loggedIn) {

    // check if page is allowed for logged off user
    if (!router.app.$utils.pagesAllowedWithoutLogin().includes(to.name)) {
      console.log('page ' + to.name + ' not allowed: not logged in')
      next({ path: '/' })
      return
    }

  } else {
    if (!['imprint', 'termsandconditions', 'privacy', 'noTeam', 'teamNotReady'].includes(to.name)) {
      // show if user is in no team
      if (!router.app.$store.state.team.uuid) {
        next({ path: '/noteam'})
        return
      }

      // show if users team is not yet ready
      if (!router.app.$store.state.team.ready) {
        next({ path: '/teamnotready'})
        return
      }
    }

    // default to welcome page for logged in user
    if (to.path === '/') {
      console.log('welcome')
      next('/welcome')
      return
    }

    // default page for project is 'overview'
    if (to.path === '/project') {
      next({ path: '/project/overview', query: to.query })
      return
    }
  }

  next()
})

export default router
