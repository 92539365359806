<!--
  Dialog to edit the permissions that a user has for an entity.
-->
<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    width="550">
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-divider
        class="pb-6">
      </v-divider>

      <v-container>
        <v-form
          @submit.prevent="okClicked()"
          v-model="formValid">
          <v-row>
            <v-col cols="8">
              <v-autocomplete
                label="Permission"
                :items="permissionItems"
                item-text="text"
                item-value="name"
                v-model="currentPermission"
                >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="cancelClicked()"
                >
                Cancel
            </v-btn>

            <v-btn
                text
                type="submit"
                :disabled="!formValid"
                >
                Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: false, // show/hide

    title: '',

    permissions: []
  },

  mounted() {
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {
      const data = {
        permissions: this.currentPermission
      }
      this.$emit('ok', data)
      this.show = false
    }
  },

  watch: {
    permissions: {
      handler(val) {
        if (val.includes('folder_admin')) {
          this.currentPermission = ['read', 'write', 'folder_admin']
        } else if (val.includes('write')) {
          this.currentPermission = ['read', 'write']
        } else {
          this.currentPermission = ['read']
        }
      },
      deep: true
    }
  },

  data() {
    return {
      formValid: false,

      currentValue: null,

      currentPermission: ['read'],

      rules: {
          required: (value)  => {
           return value !== null
          }
      },

      permissionItems: [
        {
          name: ['read'],
          text: 'read only'
        },
        {
          name: ['read', 'write'],
          text: 'read and write'
        },
        {
          name: ['read', 'write', 'folder_admin'],
          text: 'administrate'
        }
      ]
    }
  }
}
</script>