<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    max-width="600px">
    <v-card>
      <v-card-title>Invite user</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="pt-4">Invite users to your team.</div>
      </v-card-text>

      <v-form
        :disabled="inProgress"
        v-model="formValid"
        @submit.prevent="onSubmit()">
        <v-container>
          <v-row>
            <v-col class="my-0 py-0">
              Email address of the user to invite.
            </v-col>
          </v-row>

          <v-row class="my-0 py-0">
            <v-col>
              <v-text-field
                  style="width:400px"
                  dense
                  outlined
                  v-model="email"
                  :rules="emailRules"
                  autofocus
                  required>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="cancelClicked()"
              >
              Cancel
          </v-btn>

          <v-btn
              text
              type="submit"
              :disabled="!formValid || !email"
              :loading="inProgress"
              >
              Invite
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean // show/hide
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    cancelClicked() {
      this.inProgress = false
      this.email = ''
      this.$emit('cancel')
      this.show = false
    },

    onSubmit() {
      this.inProgress = true
      let data = {
        'email': this.email,
        'sendInvitationIfNeeded': true
      }
      this.$rest.post('team/user')
        .data(data)
        .onSuccess((json) => {
          this.inProgress = false
          this.email = ''
          this.show = false
          if (json['result'] === 'ALREADY_OK') {
            this.$emit('alreadyok')
          } else if (json['result'] === 'ADDED_OK') {
            this.$emit('addedok')
          } else if (json['result'] === 'INVITATION_PENDING') {
            this.$emit('invitationsent')
          } else {
            this.$emit('error')
          }
        })
        .start()

    }
  },

  watch: {
  },

  data() {
    return {
      email: '',

      formValid: false,

      inProgress: false,

      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(v) || 'Keine gültige Email Adresse'
      ]
    }
  },
}
</script>