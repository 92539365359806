<template>
  <v-container>
    <v-form
        :disabled="inProgress"
        @submit.prevent="recoverClicked()"
        v-model="formValid">
      <v-row class="grow">
      </v-row>
      <v-row
        v-if="showError">
        <v-col>
          <v-alert
            outlined
            type="warning"
            prominent
            >
            {{errorMessage}}
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Password recovery
          </h1>
        </v-col>
      </v-row>
      <v-row
        v-if="emailSent">
        <v-col>
          <v-alert
            outlined
            type="success"
            prominent
            >
            Please check your email.
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        v-if="!emailSent"
        class="text-center">
        <v-col class="mb-4">
          <span class="text-body-1 mb-3">
            Please enter your email address. We will send you an email with instructions how to reset your password.
          </span>
        </v-col>
      </v-row>
      <v-row
        class="full-height"
        v-if="!emailSent"
        >
        <v-col offset="4" cols="4">
          <v-card class="justify-center pa-2">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  name="login"
                  label="Email address"
                  type="text"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="inProgress"
                  :disabled="!formValid"
                  >Recover</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="grow">
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'RecoverPassword',

  data: () => ({
    formValid: false,

    email: '',

    showError: false,

    errorMessage: '',

    inProgress: false,

    emailSent: false,

    rules: {
      required: value => !!value || 'Required.',

      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      }
    }
  }),

  methods: {

    recoverClicked() {
      const data = {
        'email': this.email
      }
      this.inProgress = true
      this.$rest.post('/user/resetPassword')
        .data(data)
        .onSuccess((json) => {
          this.inProgress = false

          if ('result' in json && json['result'] === 'OK') {
            this.emailSent = true
          } else {
            this.showError = true
            this.errorMessage = 'Something went WRONG!'
          }
          this.inProgress = false
        })
        .start()
    },
  },



};
</script>

<style></style>
