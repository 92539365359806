<template>
  <v-dialog
    persistent
    transition="dialog-top-transition"
    v-model="show"
    max-width="800px">
    <v-card
      color="primary"
      dark
      >
        <v-card-text>
          Loading
          <v-progress-circular
            indeterminate
            color="blue"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean // show/hide
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
  },

  watch: {
  },

  data() {
    return {
    }
  },
}
</script>