<template>
  <v-container
    fluid>
    <v-row>
      <v-col>
        <v-card
          max-width="600">
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Overview
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Incoming: {{project.fileincount}} files ({{$utils.formatBytes(project.bytesreceived)}})</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Outgoing: {{project.fileoutcount}} files ({{$utils.formatBytes(project.bytessent)}})</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                Ftp Account
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">Use the ftp account data below to upload your image files. For each uploaded file your meta data and scripts are applied. Afterwards the file will be distributed to your destinations.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-title>Host: {{ftpServerHost}}
                <v-btn
                  icon
                  @click="contentCopy(ftpServerHost)"
                  >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>User: {{this.ftpServerUsername}}
                <v-btn
                  icon
                  @click="contentCopy(ftpServerUsername)"
                  >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Password: {{this.ftpServerPassword}}
                <v-btn
                  icon
                  @click="contentCopy(ftpServerPassword)"
                  >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Test your project
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">Drop an image file to test your project setup. Your meta data will be applied and the scripts will run for this file but the file will NOT be distributet to your destinations.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-alert
              v-if="uploadError !== null"
              dismissible
              dense
              type="error">
              Something went wrong: <br> {{uploadErrorPosition}} <br> {{uploadError}}
            </v-alert>
            <v-col
              class="my-0"
              v-cloak
              @drop.prevent="addDropFile"
              @dragover.prevent>
              <v-file-input
                v-model="chosenFile"
                :loading="uploadingFile"
                chips
                show-size
                outlined
                prepend-icon="mdi-file-code"
                truncate-length="60"
                accept="Image File/*.jpg"
                label="Drop an image here"
                >
              </v-file-input>
            </v-col>
          </v-list>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ProjectOverview',

  beforeDestroy() {
//    this.$store.dispatch('saveProject')
  },

  computed: {
    ...mapState([
      'project'
    ]),

    ftpServerHost() {
      return process.env.VUE_APP_FTP_HOST
    },

    ftpServerUsername() {
      if (this.project.ftpAccounts
          && this.project.ftpAccounts.length > 0) {
        return this.project.ftpAccounts[0].username
      } else {
        return ''
      }
    },

    ftpServerPassword() {
      if (this.project.ftpAccounts
          && this.project.ftpAccounts.length > 0) {
        return this.project.ftpAccounts[0].password
      } else {
        return ''
      }
    }
  },

  methods: {
    contentCopy(text) {
      this.$utils.copyTextToClipboard(text)
    },

    addDropFile(e) {
      this.chosenFile = e.dataTransfer.files[0]
      this.saveImageFile()
    },

    downloadProcessedImage(jobId, token) {
      var ifrm = document.getElementById('downloadFrame');
      var colonAndPort = ''
      if (window.location.port) {
        colonAndPort = ':' + window.location.port
      }
      const url = window.location.protocol + '//' + window.location.hostname + colonAndPort + '/api/' + this.$store.state.team.uuid + '/command/downloadresultfile/' + jobId + '/' + token
      ifrm.src = url
    },

    fileLoaded() {
      var str = this.fileContent

      var postData = {
        dbprojectid: this.$route.query.id,
        filename: this.chosenFile.name,
        content: str
      }

      const command = this.$rest.command('team/command/processimage')
                .data(postData)
                .onSuccess((json, jobId, token) => {
                  this.uploadingFile = false
                  this.uploadSuccess = true
                  this.uploadErrorPosition = null
                  this.uploadError = null

                  this.downloadProcessedImage(jobId, token)
                })
                .onError((detail) => {
                  this.uploadingFile = false
                  this.uploadSuccess = false
                  const parts = detail.split('<||||>')
                  if (parts.length === 2) {
                    this.uploadErrorPosition = parts[0]
                    this.uploadError = parts[1]
                  } else {
                    this.uploadErrorPosition = null
                    this.uploadError = detail
                  }
                })
                .start()
      this.uploadingFile = true
    },

    saveImageFile() {
      this.uploadSuccess = false
      this.uploadError = null
      this.uploadErrorPosition = null
      if (!this.chosenFile) {
        this.data = "No file Chosen"
        return
      }

      this.uploadingFile = true
      var reader = new FileReader();
      reader.readAsDataURL(this.chosenFile);
      reader.onload = () => {
        this.fileContent = reader.result;
        this.fileLoaded()
        this.chosenFile = null
      }
    },
  },

  data: () => ({
    chosenFile: null,

    uploadSuccess: false,

    uploadError: null,

    uploadErrorPosition: null,

    uploadingFile: false,

    fileContent: null,

  })
}
</script>
