import Vue from 'vue';
import Vuex from 'vuex';
import router from './router'

Vue.use(Vuex);

function localizedGroupName(name) {
  if (name === 'Owner') {
    return 'Team Owner'
  } else if (name === 'Operator') {
    return 'Operator'
  } else if (name === 'Photographer') {
    return 'Photographer'
  }

  return name
}

function findFolder(item, id) {
  if (!('children' in item)) {
    return null
  }

  if (item.id == id) {
    return item
  }

  for (let child of item.children) {
    let res = findFolder(child, id)
    if (res !== null) {
      return res
    }
  }

  return null
}

function folderById(state, itemId) {

  for (let folder of state.folders) {
    const item = findFolder(folder, itemId)
    if (item !== null) {
      return item
    }
  }

  return null
}

function removeItemR(item, itemId, type) {
  if (!item.children) {
    return null
  }

  for (let [index, child] of item.children.entries()) {

    const isFolder = child.type === 'projects' || child.type === 'ftpservers'
    if (child.id === itemId) {
      console.log('removeItemR ' + child.id + ' type: ' + child.type + ' type2: ' + type)
      if (child.type === type
         || (type === 'folder' && isFolder)) {
        console.log('removing item of type ' + child.type)
        item.children.splice(index, 1)
        return child
      }
    }

    const removedItem = removeItemR(child, itemId, type)
    if (removedItem) {
      return removedItem
    }
  }

  return null
}

function removeItem(state, itemId, type) {
  for (let folder of state.folders) {
    const removedItem = removeItemR(folder, itemId, type)
    if (removedItem) {
      return removedItem
    }
  }

  return null
}

function moveItem(state, itemId, newFolderId, itemType) {
  const item = removeItem(state, itemId, itemType)

  const folder = folderById(state, newFolderId)
  folder.children.push(item)
  item.parent = newFolderId

  for (const i in state.folders) {
    sortFolder(state.folders[i])
  }
}

function isFolder(item) {
  return item.type === 'projects'
         || item.type === 'ftpservers'
}

function rootFolderOrderValue(folder) {
  if (folder.type === 'projects') {
    return 0
  } else if (folder.type === 'ftpservers') {
    return 1
  } else if (folder.type === 'calendars') {
    return 2
  } else if (folder.type === 'settings') {
    return 3
  }
  return -1
}

function sortFolder(folder, depth=0) {

  if (folder.type !== 'ftpservers'
      && folder.type !== 'projects'
      && folder.type !== 'folders') {
    return;
  }

  for (const i in folder.children) {
    sortFolder(folder.children[i], depth + 1)
  }

  folder.children.sort((a, b) => {
    if (depth === -1) {
      const valueA = rootFolderOrderValue(a)
      const valueB = rootFolderOrderValue(b)
      if (valueA < valueB) {
        return -1
      } else if (valueA > valueB) {
        return 1
      }
      return 0
    }

    const isFolderA = isFolder(a)
    const isFolderB = isFolder(b)
    if (isFolderA && !isFolderB) {
      return -1
    }
    if (!isFolderA && isFolderB) {
      return 1
    }

    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

const store = new Vuex.Store({
  state: {
    loggedIn: false,
    team: {
      uuid: '',
      name: ''
    },

    teams: [],

    teamUsers: [],

    groups: [],

    loadingProject: false,

    debugMode: false,

    showError: false,

    errorText: '',

    eventFlags: [
      {
        id: 0,
        title: 'Ordner RICHTIG! Erstellt'
      },
      {
        id: 1,
        title: 'Caption deutsch'
      },
      {
        id: 2,
        title: 'Caption englisch'
      },
      {
        id: 3,
        title: 'KI fertig'
      },
      {
        id: 4,
        title: 'Live deutsch'
      },
      {
        id: 5,
        title: 'Live englisch getty'
      },
      {
        id: 6,
        title: 'Archiv deutsch'
      },
      {
        id: 7,
        title: 'Archiv englisch Getty'
      },
      {
        id: 8,
        title: 's-fr'
      },
      {
        id: 9,
        title: 'Imago'
      },
      {
        id: 10,
        title: 'Dina'
      },
      {
        id: 11,
        title: 'i-fr'
      },
      {
        id: 12,
        title: 'ic'
      },
      {
        id: 13,
        title: 'PA'
      },
      {
        id: 14,
        title: 'Shutter'
      },
      {
        id: 15,
        title: 'Pano'
      },
      {
        id: 16,
        title: 'Poland'
      },

    ],

    user: {
    },

    project: {
      id: 1
    },

    ftpServer: {
      id: 1
    },

    folders: [
    ],

    calendarEntries: [
    ],

    externalCalendars: [
    ]
  },

  // NOTE: Mutations should always be synchronous. Asynchrone things should be done
  // in actions
  mutations: {
    setLoggedIn(state) {
      if (state.loggedIn) {
        return
      }
      state.loggedIn = true
      router.push('/welcome').catch(()=>{})
    },

    setShowError(state, value) {
      state.showError = value
    },

    setLoggedOut(state) {
      state.loggedIn = false
      state.user = {}
      state.team = {
        'uuid': '',
        'name': '',
        'users': ''
      }
      state.teams = []
      state.teamUsers = []
      state.project = {}
      state.ftpServer = {}
      state.folders = []
      state.calendarEntries = []
      state.externalCalendars = []
    },

    setGroups(state, groups) {
      state.groups = groups
    },

    setUser(state, user) {
      state.user = user
    },

    setUserFirstName(state, firstName) {
      state.user.firstName = firstName
    },

    setUserLastName(state, lastName) {
      state.user.lastName = lastName
    },

    setTeam(state, team) {
      state.team = team
      state.team.users = []
      state.user.groups = []
      state.user.isOwner = false
      state.user.isPhotographer = false
      state.user.isOperator = false
      if (team.ready) {
        this._vm.$rest.get('team/user/groups')
        .onSuccess((json) => {
          state.user.groups = json
          if (state.user.groups.length !== 1) {
            console.error('user must be in ONE group and one group only')
          } else {
            state.user.isOwner = state.user.groups[0].name === 'Owner'
            state.user.isOperator = state.user.groups[0].name === 'Operator'
            state.user.isPhotographer = state.user.groups[0].name === 'Photographer'
            state.user.groups[0].localizedName = localizedGroupName(state.user.groups[0].name)
          }
        })
        .start();

        // request team users (FIXME: only operators and owners should do this)
        this._vm.$rest.get('team/users')
        .onSuccess((json) => {
          let members = json['members']
          var users = []
          if (members) {
            members.forEach((member) => {
              let user = {
                'dbuserid': member.dbuserid,
                'email': member.email,
                'fullname': member.firstname + ' ' + member.lastname,
                'firstname': member.firstname,
                'lastname': member.lastname,
                'group': member.groups[0],
                'isOwner': false,
                'accreditation_number': member.accreditation_number
              }

              for (let i = 0; i < member.groups.length; i++) {
                if (member.groups[i].name == 'Owner') {
                  user.isOwner = true
                  break
                }
              }
              users.push(user)
            });
            state.team.users = users
          }
        })
        .onError((json) => {
          if (!('error' in json)) {
            return false // show global error dialog
          }

          const error = json['error']['code'];
          if (error === 'MissingPermission') {

            return true // this is ok - continue
          }

          return false
        })
        .start()


        this._vm.$rest.get('team/groups')
        .onSuccess((json) => {
          state.groups = json['groups']
          // set localized names
          for (var i in state.groups) {
            state.groups[i].localizedName = localizedGroupName(state.groups[i].name)
          }
        })
        .start();
      }

      // remeber selected team
      localStorage.team = team
    },

    setTeams(state, teams) {
      state.teams = teams
    },

    enableDebugMode(state) {
      state.debugMode = true
    },

    disableDebugMode(state) {
      state.debugMode = false
    },

    setRestError(state, text) {
      state.showError = true
      state.errorText = text
    },

    setProject(state, project) {
      for (const i in project.ftpServers) {
        if (project.ftpServers[i].overridepath !== null) {
          project.ftpServers[i].path = project.ftpServers[i].overridepath
        } else {
          project.ftpServers[i].path = project.ftpServers[i].defaultpath
        }
      }
      state.project = project
    },

    setFolders(state, folders) {
      state.folders = folders

      const rootFolder = {
        'type': 'folders',
        'children': folders
      };

      sortFolder(rootFolder, -1)
      state.folders = rootFolder.children
    },

    setLoadingProject(state, loading) {
      state.loadingProject = loading
    },

    setCalendarEntries(state, calendarEntries) {
      state.calendarEntries = calendarEntries
    },

    addCalendarEntry(state, calendarEntry) {
      // remove existing entry
      for (let i = 0; i < state.calendarEntries.length; i++) {
        if (state.calendarEntries[i].dbcalendarentryid === calendarEntry.dbcalendarentryid) {
          state.calendarEntries.splice(i, 1)
          break
         }
      }

      calendarEntry.localizedStartDate = this._vm.$utils.localizeDate(calendarEntry['startdatetime'])
      calendarEntry.localizedStartTime = this._vm.$utils.localizeTime(calendarEntry['startdatetime'])
      calendarEntry.startDate = calendarEntry['startdatetime'].substr(0, 10)
      calendarEntry.startjsdate = new Date(calendarEntry['startdatetime'])
      this._vm.$utils.insertSorted(state.calendarEntries,
                                   calendarEntry,
                                   (a, b) => {
                                     if (a.startjsdate.getTime() === b.startjsdate.getTime()) {
                                       return a.dbcalendarentryid < b.dbcalendarentryid
                                     }
                                     return a.startjsdate.getTime() < b.startjsdate.getTime()
                                   })
    },

    setExternalCalendars(state, calendars) {
      state.externalCalendars = calendars
    },

    removeExternalCalendar(state, calendarId) {
      for (let i = 0; i < state.externalCalendars.length; i++) {
        if (state.externalCalendars[i].dbcalendarid === calendarId) {
          state.externalCalendars.splice(i, 1)
          break
        }
      }
    },

    addExternalCalendar(state, calendar) {
      state.externalCalendars.push(calendar)
    }

  },

  actions: {
    updateUser({commit, state}) {
      this._vm.$rest.get('user/info')
        .onSuccess((json) => {
          if (!json['logged_in']) {
            commit("setLoggedOut")
            if (!this._vm.$utils.pagesAllowedWithoutLogin().includes(router.currentRoute.name)) {
              router.push('/').catch(()=>{})
            }
            this._vm.$utils.storeIsReady()
          } else {
            let initials = json['firstName'].toUpperCase().charAt(0) + json['lastName'].toUpperCase().charAt(0)
            let user = {
              'id': json['id'],
              'email': json['email'],
              'firstName': json['firstName'],
              'lastName': json['lastName'],
              'fullName': json['firstName'] + ' ' + json['lastName'],
              'initials': initials
            }
            commit("setLoggedIn")
            commit('setUser', user)

            const teams = json['member_of']
            if (!teams || teams.length === 0) {
              commit('setTeam', {})
            } else {
              // auto select first team
              let team = teams[0]
              commit('setTeam', {
                'uuid': team.teamid,
                'name': team.name,
                'ready': team.ready
              })

              commit('setTeams', teams)
            }

            this._vm.$utils.storeIsReady()

            router.push('/').catch(()=>{})
          }
        })
        .onError(() => {
            commit("setLoggedOut")
            router.push('/login').catch(()=>{})
        })
        .start()
    },

    // change firstname/lastname
    changeUserName({commit, state}, user) {
      return new Promise((resolve, reject) => {
        this._vm.$rest.put('/user/info')
          .data(user)
          .onSuccess((json) => {
            commit('setUserFirstName', user.firstName)
            commit('setUserLastName', user.lastName)
            resolve()
          })
          .start()
      })
    },

    loadFolders({commit, state}) {
      this._vm.$rest.get('team/folders')
        .onSuccess((json) => {
          commit('setFolders', json['folders'])
        })
        .start()
    },

    loadProject({commit, state}, projectId) {
      commit('setLoadingProject', true)
      this._vm.$rest.get('team/project/' + projectId)
        .onSuccess((json) => {
          commit('setProject', json['project'])
          commit('setLoadingProject', false)
        })
        .start()
    },

    saveProject({commit, state}) {
      this._vm.$rest.put('team/project/' + state.project.projectid)
          .data(state.project)
          .onSuccess((json) => {
          })
          .start()
    },

    deleteProject({commit, state}, projectId) {
      this._vm.$rest.delete('team/project/' + projectId)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
            if (state.project && state.project.id == projectId) {
              state.project = null
            }
            removeItem(this.state, projectId, 'project');
          }
        })
        .start()
    },

    deleteFtpServer({commit, state}, ftpServerId) {
      this._vm.$rest.delete('team/ftpserver/' + ftpServerId)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
            if (state.ftpServer && state.ftpServer.id == ftpServerId) {
              state.ftpServer = null
            }
            removeItem(this.state, ftpServerId, 'ftpserver');
          }
        })
        .start()
    },

    moveProject({commit, state}, data) {
      this._vm.$rest.post('team/folder/' + data.newFolderId + '/project/' + data.projectId)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
            moveItem(state, data.projectId, data.newFolderId, 'project')
          }
        })
        .start()
    },

    moveFtpServer({commit, state}, data) {
      this._vm.$rest.post('team/folder/' + data.newFolderId + '/ftpserver/' + data.ftpServerId)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
            moveItem(state, data.ftpServerId, data.newFolderId, 'ftpserver')
          }
        })
        .start()
    },

    moveFolder({commit, state}, data) {
      this._vm.$rest.post('team/folder/' + data.newFolderId + '/folder/' + data.folderId)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
            moveItem(state, data.folderId, data.newFolderId, 'folder')
          }
        })
        .start()
    },

    addFtpServerToProject({commit, state, dispatch}, ftpServerId) {
      this._vm.$rest.post('team/project/' + state.project.projectid + '/ftpserver/' + ftpServerId)
                .onSuccess((json) => {
                  dispatch('loadProject', state.project.projectid)
                })
                .start()
    },

    removeFtpServerFromProject({commit, state, dispatch}, ftpServerId) {
      this._vm.$rest.delete('team/project/' + state.project.projectid + '/ftpserver/' + ftpServerId)
                .onSuccess((json) => {
                  dispatch('loadProject', state.project.projectid)
                })
                .start()
    },

    addDestinationProjectToProject({commit, state, dispatch}, destinationProjectId) {
      this._vm.$rest.post('team/project/' + state.project.projectid + '/destinationproject/' + destinationProjectId)
                .onSuccess((json) => {
                  dispatch('loadProject', state.project.projectid)
                })
                .start()
    },

    removeDestinationProjectFromProject({commit, state, dispatch}, destinationProjectId) {
      this._vm.$rest.delete('team/project/' + state.project.projectid + '/destinationproject/' + destinationProjectId)
                .onSuccess((json) => {
                  dispatch('loadProject', state.project.projectid)
                })
                .start()
    },

    setProjectFtpServerPath({commit, state, dispatch}, value) {
      const data = {
        'path': value.path
      }
      this._vm.$rest.put('team/project/' + state.project.projectid + '/ftpserver/' + value.dbftpserverid)
      .data(data)
      .onSuccess((json) => {
        dispatch('loadProject', state.project.projectid)
      })
      .start()
    },

    newFolder({commit, state, dispatch}, folder) {
      this._vm.$rest.post('team/folder')
            .data(folder)
            .onSuccess((json) => {
              if (json['result'] === 'OK') {
                var parentFolder = folderById(this.state, folder.parentFolderId)
                const folderId = json['folderId']
                parentFolder.children.push({
                  id: folderId,
                  key: 'projects-' + folderId,
                  parent: folder.parentFolderId,
                  name: folder.name,
                  type: folder.type,
                  children: []
                })

                sortFolder(parentFolder)
              }
            })
            .start();
    },

    deleteFolder({commit, state, dispatch}, folderId) {
      this._vm.$rest.delete('team/folder/' + folderId)
            .onSuccess((json) => {
              if (json['result'] === 'OK') {
                removeItem(this.state, folderId, 'folder')
              }
            })
            .start();
    },

    renameFolder({commit, state, dispatch}, folder) {
      this._vm.$rest.put('team/folder/' + folder.id)
        .data(folder)
        .onSuccess((json) => {
          if (json['result'] === 'OK') {
          }
        })
        .start()
    },

    renameProject({commit, state, dispatch}, project) {
      this._vm.$rest.put('team/project/' + project.id)
        .data(project)
        .onSuccess((json) => {

        })
        .start()
    },

    renameFtpServer({commit, state, dispatch}, ftpServer) {
      this._vm.$rest.put('team/ftpserver/' + ftpServer.id)
        .data(ftpServer)
        .onSuccess((json) => {

        })
        .start()
    },

    addProject({commit, state, dispatch}, project) {
      const parentFolder = folderById(this.state, project.parentFolderId)
      if (!parentFolder) {
        console.error('folder with id ' + project.parentFolderId + ' not found')
        return
      }
      parentFolder.children.push({
        id: project.id,
        name: project.name,
        type: 'project',
        key: 'project-' + project.id,
        parent: parentFolder.id,
        children: []
      })

      sortFolder(parentFolder)
    },

    addFtpServer({commit, state, dispatch}, ftpServer) {
      const parentFolder = folderById(this.state, ftpServer.parentFolderId)
      if (!parentFolder) {
        console.error('folder with id ' + ftpServer.parentFolderId + ' not found')
        return
      }
      parentFolder.children.push({
        id: ftpServer.id,
        name: ftpServer.name,
        type: 'ftpserver',
        key: 'ftpserver-' + ftpServer.id,
        parent: parentFolder.id,
        children: []
      })

      sortFolder(parentFolder)
    },

    loadCalendar({commit, state}, calendarId) {
      this._vm.$rest.get('team/calendar/' + calendarId)
        .onSuccess((json) => {
          if (json['result'] == 'OK') {
            let calendar = json['calendar']
            if (calendar.type === 'google') {
              commit('addExternalCalendar', calendar)
            }
          }
        })
        .start()
    },

    loadExternalCalendars({commit, state}) {
      this._vm.$rest.get('team/externalcalendars')
        .onSuccess((json) => {
          commit('setExternalCalendars', json['calendars'])
        })
        .start()
    },

    deleteExternalCalendar({commit, state}, calendarId) {
      this._vm.$rest.delete('team/calendar/' + calendarId)
        .onSuccess((json) => {
          commit('removeExternalCalendar', calendarId)
        })
        .start()
    },

    loadCalendarEntries({commit, state}, info) {
      const params = {
        calendarIds: info.calendarIds,
        startDate: info.startDate
      }
      this._vm.$rest.post('team/calendar/entries')
        .data(params)
        .onSuccess((json) => {
          var entries = json['entries']
          for (var entry of entries) {
            entry.localizedStartDate = this._vm.$utils.localizeDate(entry['startdatetime'])
            entry.localizedStartTime = this._vm.$utils.localizeTime(entry['startdatetime'])
            entry.startDate = entry['startdatetime'].substr(0, 10)
            entry.startjsdate = new Date(entry['startdatetime'])
            entry.confirmedByCurrentUser = false
            entry.flagDictionary = {}
            for (const i in state.eventFlags) {
              const value = (entry.flags & (1 << state.eventFlags[i].id)) != 0
              entry.flagDictionary[state.eventFlags[i].id] = value
            }

            entry.bookings.forEach(conf => {
              if (conf.dbuserid === state.user.id) {
                entry.confirmedByCurrentUser = true
              }
            });
          }

          commit('setCalendarEntries', json['entries'])
        })
        .start()
    },

    deleteCalendarEntry({commit, state}, eventId) {
      this._vm.$rest.delete('team/calendar/entry/' + eventId)
        .onSuccess((json) => {
          for (let [index, child] of state.calendarEntries.entries()) {
            if (child.dbcalendarentryid === eventId) {
              state.calendarEntries.splice(index, 1)
              break
            }
          }
        })
        .start()
    },

    saveNewCalendarEntry({commit, state}, data) {
      this._vm.$rest.post('team/calendar/' + data.dbcalendarid + '/entry')
        .data(data.entry)
        .onSuccess((json) => {
          if (json['result'] != 'OK') {
            return
          }
          data.entry.dbcalendarentryid = json['calendarentryid']
          commit('addCalendarEntry', {...data.entry})
        })
        .start()
    },

    updateCalendarEntry({commit, state}, data) {
      this._vm.$rest.put('team/calendar/' + data.dbcalendarid + '/entry/' + data.entry.dbcalendarentryid)
      .data(data.entry)
      .onSuccess((json) => {
        commit('addCalendarEntry', {...data.entry})
      })
      .start()
    },

    updateBooking(state, booking) {
      this._vm.$rest.post('team/booking')
        .data(booking)
        .onSuccess((json) => {
          //commit('addCalendarEntry', {...data.entry})
        })
        .start()
    },

    setShowError({ commit }, newValue) {
      commit("setShowError", newValue);
    },
  }

});

export default store
