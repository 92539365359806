<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    max-width="600px">

    <v-card>
      <v-form
        :disabled="inProgress"
        @submit.prevent="confirmClicked()">

        <v-card-title>Suggest events to Photographers</v-card-title>

        <v-divider
          class="pb-6">
        </v-divider>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="users"
          :search="search"
          item-key="dbuserid"
          show-select
          hide-default-footer
          :items-per-page=-1
          style="min-width: 550px !important; max-height: 400px; overflow: auto">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>

        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="cancelClicked()"
            >
            Cancel
          </v-btn>

          <v-btn
              text
              type="submit"
              :disabled="selected.length == 0"
              :loading="inProgress"
              >
              Invite
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean,
    entryIds: []
  },

  computed: {
    ...mapState([
      'team',
      'user'
    ]),

    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },

  methods: {
    onShow() {
      console.log('onShow')
      this.users = []
      for (const i in this.team.users) {
        const user = this.team.users[i]
        if (user.dbuserid !== this.user.id) {
          this.users.push(user)
        }
      }
    },

    filtter(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().indexOf(search) !== -1
    },

    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    confirmClicked() {
      this.inProgress = true
      const userIds = this.selected.map((user) => {
        return user.dbuserid
      })
      const data = {
        'userIds': userIds,
        'entryIds': this.entryIds
      }
      this.$rest.post('team/calendar/suggestentries')
        .data(data)
        .onSuccess((json) => {
          this.inProgress = false
          this.$emit('ok')
        })
        .start()
    },
  },

  watch: {
    value(v) {
      if (v) {
        this.onShow()
      }
    }
  },

  data() {
    return {
      inProgress: false,

      search: '',

      selected: [],

      users: [],

      headers: [
        {
          text: 'Name',
          value: 'fullname',
          align: 'left'
        },
        {
          text: 'Email',
          value: 'email',
          align: 'left'
        },
      ],
    }
  },
}
</script>