<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider="->">
    </v-breadcrumbs>
    <v-divider style="margin-bottom:20px">
    </v-divider>
      <v-row align="end">
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="textFieldName"
            label="Name"
            maxLength="64"
            :disabled="saving"
            v-model="project.name"
            v-bind="nameInputProps"
            v-on:input="onNameChanged"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        class="mt-6"
        color="primary"
        v-on:click="onSaveClicked()"
        :loading="saving"
        >Projekt anlegen
      </v-btn>
    </v-container>
  </template>
<script>

export default {
  name: 'NewProject',

  data: () => ({
    breadcrumbs: [
      {'text': 'PHOTO-Hub'},
      {'text': 'Neues Projekte'}
    ],

    saving: false,

    project: {
      name: '',
      parentFolderId: -1
    },
    nameInputProps: { },
    descInputProps: { }

  }),

  methods: {
    onNameChanged() {
      this.nameInputProps = {}
    },

    onSaveClicked() {
      this.nameInputProps = {}

      if (this.project.name === '') {
        this.nameInputProps = { 'error-messages': "Der Projektname darf nicht leer sein." }
        return
      }

      this.saving = true

      this.project.parentFolderId = this.$route.query.parentfolder

      this.$rest.post('team/project')
                .data(this.project)
                .onSuccess((json) => {
                  if (json['result'] === 'OK') {
                    this.project.id = parseInt(json['projectId'])
                    this.$store.dispatch('addProject', this.project)
                    this.$router.push({ path: '/project', query: { id: json['projectId'] } }).catch(() => {})
                  }
                  this.saving = false
                })
                .start()
    }
  }
}
</script>
