<template>
  <v-dialog
    :persistent="inProgress"
    transition="dialog-top-transition"
    v-model="show"
    max-width="800px">
    <v-card>
      <v-card-title>Add external calendar</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="pt-4">Add an external google calendar.</div>
      </v-card-text>

      <v-form
        :disabled="inProgress"
        @submit.prevent="createClicked()"
        v-model="formValid">
        <v-container>
          <v-row>
            <v-col class="my-0 py-0">
              Calendar URL
            </v-col>
          </v-row>

          <v-row class="my-0 py-0">
            <v-col>
              <v-text-field
                  style="width:400px"
                  dense
                  outlined
                  v-model="calendarUrl"
                  :label="editLabelText"
                  :rules="urlRules"
                  :error-messages="errorMessage"
                  @change="errorMessage=null"
                  @keydown="errorMessage=null"
                  required>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      <v-card-actions class="justify-end">
        <v-btn
            text
            @click="cancelClicked()"
            :disabled="inProgress"
            >
            Cancel
        </v-btn>

        <v-btn
            text
            type="submit"
            :disabled="!formValid"
            :loading="inProgress"
            >
            Add
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean, // show/hide
    title: String,
    message: String,
    editLabelText: String
  },

  computed: {
    ...mapState([
      'externalCalendars'
    ]),

    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
      this.calendarUrl = ''
    },

    createClicked() {
      this.inProgress = true
      let data = {
        calendarUrl: this.calendarUrl.trim()
      }
      this.$rest.command('team/command/createexternalcalendar')
        .data(data)
        .onSuccess((detail) => {
          this.$store.dispatch('loadExternalCalendars')
          this.inProgress = false
          this.show = false
          this.calendarUrl = ''
        })
        .onError((detail) => {
          this.errorMessage = detail
          this.inProgress = false
        })
        .start()
    }
  },


  data() {
    return {
        calendarUrl: '',

        errorMessage: null,

        formValid: false,

        inProgress: false,

        urlRules: [
          v =>  v.length >= 3
        ]
    }
  },
}
</script>