<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <span class="text-h6 display-2 mb-3">
          Your name
        </span>
        <br/>
        <span class="text-body-1 display-2  mb-3">
          Update your full name here.
        </span>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="firstName"
          :rules="[rules.required]"
          >
        </v-text-field>
        <v-btn
          :disabled="!userDirty"
          :loading="savingUser"
          @click="saveUser" >
          Update Name
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="lastName"
          :rules="[rules.required]"
          >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider class="ma-4" ></v-divider>
    <v-row>
      <v-col cols="3">
        <span class="text-h6 display-2 mb-3">
          Teams
        </span>
        <br/>
        <span class="text-body-1 display-2  mb-3">
          You are member in the following teams
        </span>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="teams"
        :hide-default-footer="true"
        item-key="id">

      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Welcome',

    data: () => ({
      firstName: '',

      lastName: '',

      savingUser: false,

      rules: {
        required: value => !!value || 'Required.'
      },

      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        }
      ]

    }),

    computed: {
      userDirty: function() {
        return this.firstName !== this.$store.state.user.firstName
                || this.lastName !== this.$store.state.user.lastName
      },

      ...mapState([
        'teams'
      ]),
    },

    mounted() {
      this.$utils.onAppReady(() => {
        this.firstName = this.$store.state.user.firstName
        this.lastName = this.$store.state.user.lastName
      })

    },

    methods: {
      saveUser() {
        this.savingUser = true

        this.$store.dispatch('changeUserName', {
          firstName: this.firstName,
          lastName: this.lastName
        }).then(() => {
          this.savingUser = false
        })
      }
    }

  }
</script>
