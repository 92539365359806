<template>
  <v-container
    fluid>
    <ScriptUpload target="project" :projectId="this.$route.query.id"></ScriptUpload>
  </v-container>
</template>

<script>
import ScriptUpload from './ScriptUpload'
import { mapState } from 'vuex'

export default {

  name: 'ProjectOverview',

  computed: {
    ...mapState([
      'project'
    ])
  },

  components: {
    ScriptUpload
  }
}
</script>
