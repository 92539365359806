<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>
    <v-row
      v-if="inProgress"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Processing
        </span>
      </v-col>
    </v-row>
    <v-row
      class="text-center">
      <v-col>
        <v-progress-circular
          v-if="inProgress"
          :size="50"
          color="primary"
          indeterminate
          >
        </v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      v-if="errorMessage">
      <v-col>
        <v-alert
          outlined
          type="warning"
          prominent
          >
          {{errorMessage}}
        </v-alert>
      </v-col>
    </v-row>

    <v-row
      v-if="succeeded">
      <v-col>
        <v-alert
          outlined
          type="success"
          prominent
          >
          Successful. You will be redirected to the login page automatically in some seconds, click <a href="/">here</a> to go there automatically.
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>
import router from '../router'
import { mapState } from 'vuex'

export default {
  name: 'AcceptPhotographerInvitation',

  mounted() {
    this.$utils.onAppReady(() => {
      this.start()
    })
  },

  computed: {
    ...mapState([
      'user'
    ]),
  },

  methods: {
    start() {
      // parameters: userid, ciid (calendar item id)
      if (!('userid' in this.$route.query)
          || !('ciid' in this.$route.query)) {
        this.errorMessage = 'invalid parameters'
        return
      }

      const userId = this.$route.query.userid
      const ciid = this.$route.query.ciid

      if (userId !== this.$store.user.id) {
        this.errorMessage = 'invalid user id';
        return
      }

      const data = {
        'token': this.$route.query.token,
        'selector': this.$route.query.selector
      }

      this.inProgress = true
      this.$rest.post('/calendar/suggestentries' + ciid + '/confirm')
        .data(data)
        .onSuccess((json) => {
          if ('result' in json && json['result'] === 'OK') {
            this.succeeded = true
          } else if ('error' in json ) {
            this.errorMessage = 'Something went wrong - please try again'
          } else {
            this.errorMessage = 'Something went WRONG!'
          }
          this.inProgress = false
        })
        .start()
    },

  },

  data() {
    return {
      inProgress: false,

      errorMessage: '',

      succeeded: false
    }
  }
};
</script>

<style></style>
