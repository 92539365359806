var teamUuid = ''

class Request {
    constructor(method, entity) {
        this.method = method
        this.entity = entity
        this.jsonData = null
        this.successFunc = function() {}
        this.errorFunc = function() {}
    }

    start() {
        var url = ''
        if (process.env.VUE_APP_BACKEND_PREFIX !== undefined) {
            url += process.env.VUE_APP_BACKEND_PREFIX;
        }
        url += this.entity

        if (url.includes('team')) {
            let teamUuid = window.vueobject.$store.state.team.uuid
            url = url.replace('team/', teamUuid + '/')
        }

        url = url.replace('//', '/')

        $.ajax(
            url,
            {
                type: this.method,
                data: (this.method === 'POST' || this.method === 'PUT' || this.method == 'DELETE') && this.jsonData !== null ? JSON.stringify(this.jsonData) : null,
                success: (data, status, xhr) => {
                    if (data.hasOwnProperty('error')) {
                        const userErrorResult = this.errorFunc(data)
                        if (userErrorResult !== true) {
                            window.vueobject.$store.commit("setRestError", data.error);
                        }
                    } else {
                        try {
                            this.successFunc(data)
                        } catch (e) {
                            window.vueobject.$store.commit("setRestError", e.message);
                        }
                    }
                }
            }).fail(() => {
                window.vueobject.$store.commit("setRestError", 'FAILED');
            });
    }

    data(data) {
        this.jsonData = data
        return this
    }

    onSuccess(func) {
        this.successFunc = func
        return this
    }

    onError(func) {
        this.errorFunc = func
        return this
    }
}

class Command {
    constructor(entity) {
        this.entity = entity
        this.commandId = null
        this.token = null
        this.jsonData = null
        this.successFunc = function() {}
        this.errorFunc = function() {}
    }

    start() {
        var url = ''
        if (process.env.VUE_APP_BACKEND_PREFIX !== undefined) {
            url += process.env.VUE_APP_BACKEND_PREFIX;
        }
        url += this.entity

        let teamUuid = window.vueobject.$store.state.team.uuid
        url = url.replace('team/', teamUuid + '/')

        $.ajax(
            url, {
            type: 'POST',
            data: this.jsonData !== null ? JSON.stringify(this.jsonData) : null,
            success: (data, status, xhr) => {
                if (data["result"] == 'OK') {
                    this.commandId = data['commandId']
                    this.token = data['token']
                    setTimeout(() => { this.pollCommandState() }, 1000)
                } else {
                    this.errorFunc()
                }
            }
            }).fail(() => {
                this.errorFunc()
            });
    }

    pollCommandState() {
        rest.get('team/command/state/' + this.commandId + '/' + this.token)
        .onSuccess((json) => {
          if (json['state'] === null) {
            setTimeout(() => { this.pollCommandState() }, 1000);
          } else {
            const commandId = this.commandId
            const token = this.token
            this.commandId = null
            this.token = null
            const state = json['state']
            if (state === 'ok') {
              this.successFunc(json['detail'], commandId, token)
            } else if (state === 'error') {
              this.errorFunc(json['detail'])
            }
          }
        })
        .start()
    }

    data(data) {
        this.jsonData = data
        return this
    }

    onSuccess(func) {
        this.successFunc = func
        return this
    }

    onError(func) {
        this.errorFunc = func
        return this
    }

}

export const rest = {
    setTeamUuid(uuid) {
        teamUuid = uuid
    },

    post: (entity) => {
        return new Request('POST', entity)
    },

    put: (entity) => {
        return new Request('PUT', entity)
    },

    get: (entity) => {
        return new Request('GET', entity)
    },

    delete: (entity) => {
        return new Request('DELETE', entity)
    },

    add: (entity) => {
        return new Request('ADD', entity)
    },

    remove: (entity) => {
        return new Request('REMOVE', entity)
    },

    command: (entity) => {
        return new Command(entity)
    }
}
