<template>
  <v-container
    fluid>
    <v-row>
      <v-col>
        <v-btn
          class="ma-2"
          depressed
          @click="askToClearMetaData()"
          color="error">
          Clear
        </v-btn>
        <v-btn
          class="ma-2"
          @click="downloadXmpFile"
          depressed>
          Download XMP
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="my-0"
        cols="4"
        v-cloak
        v-if="project.canWrite"
        @drop.prevent="addDropFile"
        @dragover.prevent>
          <v-file-input
            v-model="chosenFile"
            :disabled="uploadingXmpFile"
            chips
            show-size
            outlined
            prepend-icon="mdi-file-code"
            truncate-length="60"
            accept="XMP-Datei/*.xmp"
            :label="$t('project.metadata.dropXmpFile')"
            @change="saveXmpFile()"
            >
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="uploadingXmpFile"
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
              </v-fade-transition>
            </template>
          </v-file-input>
      </v-col>
      <v-col
        cols="3">
        <v-alert
          v-if="uploadError !== null"
          type="error">
          XMP-Datei konnte nicht geladen werden: {{uploadError}}
        </v-alert>
        <v-alert
          v-if="uploadSuccess"
          type="success">
          XMP-Datei wurde hochgeladen.
        </v-alert>
      </v-col>
    </v-row>

    <v-switch
      v-model="hideDisabledValues"
      class="ma-2"
      label="Hide unset values"
      depressed>
    </v-switch>

    <v-tabs
      v-model="activeTab"
      @change="activeTabChanged()">
      <v-tab>Image Content</v-tab>
      <v-tab>Image Location</v-tab>
      <v-tab>Location Taken/Shown</v-tab>
      <v-tab>Image Rights</v-tab>
      <v-tab>More</v-tab>
    </v-tabs>

    <v-row
      class="mt-0 mb-0 pt-0 pb-0"
      v-for="value in sortedValues"
      :key="value.id">
      <v-col
        class="mt-0 mb-0 pt-0 pb-0"
        cols="6">

        <!-- Simple property (edit field) -->
        <v-card
          v-if="value.type == 'simple' && value.uiCategory === activeTab && (hideDisabledValues === false || value.mode !== 'off')"
          class="my-2 mx-0 pa-0">
          <v-container
            class="pa-0 ma-0">
            <v-row
              class="pa-0 ma-0"
              align="center">
              <v-col
                class="pa-0 ma-0">
                <v-card-title
                  class="py-1 text-h6">
                  {{value.title}}
                </v-card-title>
              </v-col>
              <v-col class="text-right pa-0" >
                <v-btn-toggle
                        v-model="value.mode"
                        @change="xmpValueModeChanged(value)"
                        tile
                        dense
                        group
                        class="pa-0 ma-0"
                      >
                        <v-btn
                          :disabled="!project.canWrite"
                          value="off">
                          {{ $t('project.metadata.modeOff') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="replace">
                          {{ $t('project.metadata.modeReplace') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="append">
                          {{ $t('project.metadata.modeAppend') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="prepend">
                          {{ $t('project.metadata.modePrepend') }}
                        </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0">
              <v-card-subtitle
                class="my-0 py-0">
                  {{value.desc}}
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="$store.state.debugMode">
                  [{{value.xmpName}}, type={{value.type}}, propTypeIds={{value.propertyTypeIds}}]
                </v-card-subtitle>
                <v-text-field
                  class="mx-4 my-0 pa-0"
                  :ref='"input_" + value.propertyTypeIds'
                  @input="xmpValueChanged(value)"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  :placeholder="value.title"
                  maxLength="1024"
                  dense >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!--
          Alt array
          We do only support the default value 'x-default' in this control, not the multilanguage variant. This is how it seems to be used by most.
        -->
        <v-card
          v-if="value.type == 'alt' && value.uiCategory === activeTab && (hideDisabledValues === false || value.mode !== 'off')"
          class="my-2 mx-0 pa-0">
          <v-container
            class="pa-0 ma-0">
            <v-row
              class="pa-0 ma-0"
              align="center">
              <v-col
                class="pa-0 ma-0">
                <v-card-title
                  class="py-1 text-h6">
                  {{value.title}}
                </v-card-title>
              </v-col>
              <v-col
                class="text-right pa-0">
                <v-btn-toggle
                        v-model="value.mode"
                        @change="xmpValueModeChanged(value)"
                        tile
                        dense
                        group
                      >
                        <v-btn
                          :disabled="!project.canWrite"
                          value="off">
                          {{ $t('project.metadata.modeOff') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="replace">
                          {{ $t('project.metadata.modeReplace') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="append">
                          {{ $t('project.metadata.modeAppend') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="prepend">
                          {{ $t('project.metadata.modePrepend') }}
                        </v-btn>

                      </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0">
                <v-card-subtitle
                 class="my-0 py-0">
                  {{value.desc}}
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="$store.state.debugMode">
                  [AltArray Control; xmp="{{value.xmpName}}"; type={{value.type}}; propTypeIds={{value.propertyTypeIds}}, ref=input_{{value.propertyTypeIds}}]
                </v-card-subtitle>
                <v-text-field
                  :ref='"input_" + value.propertyTypeIds + "_x-default"'
                  class="mx-4 my-0 pa-0"
                  @input="xmpAltValueChanged(value, 'x-default')"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  :placeholder="value.title"
                  maxLength="1024"
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- simple bag -->
        <v-card
          v-if="value.fields === null && (value.type === 'bag' || value.type === 'seq') && value.uiCategory === activeTab && (hideDisabledValues === false || value.mode !== 'off')"
          class="my-2 mx-0 pa-0">
          <v-container
            class="pa-0 ma-0">
            <v-row
              class="pa-0 ma-0"
              align="center">
              <v-col
                class="pa-0 ma-0">
                <v-card-title
                  class="py-1 text-h6">
                  {{value.title}}
                </v-card-title>
              </v-col>
              <v-col
                class="text-right">
                <v-btn-toggle
                        v-model="value.mode"
                        @change="xmpValueModeChanged(value)"
                        tile
                        dense
                        group
                      >
                        <v-btn
                          :disabled="!project.canWrite"
                          value="off">
                          {{ $t('project.metadata.modeOff') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="replace">
                          {{ $t('project.metadata.modeReplace') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="merge">
                          {{ $t('project.metadata.modeMerge') }}
                        </v-btn>

                      </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
              class="my-0 py-0">
                <v-card-subtitle
                  class="my-0 py-0">
                  {{value.desc}}<br>
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="$store.state.debugMode">
                  [array {{value.xmpName}}, type={{value.type}}, propTypeIds={{value.propertyTypeIds}}, ref=input_{{value.propertyTypeIds}}_i]
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0"
              v-for="(value2, position) in value.value"
              :key="value.id + '.' + position">
              <v-col
                class="my-0 py-0">
                <v-text-field
                  class="mx-4 my-0 pa-0"
                  :ref='"input_" + value.propertyTypeIds + "_" + position'
                  @input="xmpArrayValueChanged(value, position, null)"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  :placeholder="value.title"
                  append-outer-icon="mdi-close-circle"
                  @click:append-outer="removeArrayItem(value.id, position)"
                  maxLength="1024"
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0 text-right">
                <v-btn
                  @click="addArrayItem(value.id)"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  class="ma-2 pa-2"
                  small
                  fab>
                  <v-icon
                    small
                    class="mx-4 my-2">
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- structure -->
        <v-card
          v-if="value.type == 'structure' && value.uiCategory === activeTab && (hideDisabledValues === false || value.mode !== 'off')"
          class="mt-0 mb-0 pt-0 pb-0">
          <v-container
            class="pa-0 ma-0">
            <v-row
              class="pa-0 ma-0"
              align="center">
              <v-col
                class="pa-0 ma-0">
                <v-card-title
                  class="py-1 text-h6">
                  {{value.title}}<br>
                </v-card-title>
              </v-col>
              <v-col
                class="text-right pa-0">
                <v-btn-toggle
                  v-model="value.mode"
                  @change="xmpValueModeChanged(value)"
                  tile
                  dense
                  group
                >
                  <v-btn
                    :disabled="!project.canWrite"
                    value="off">
                    {{ $t('project.metadata.modeOff') }}
                  </v-btn>

                  <v-btn
                    :disabled="!project.canWrite"
                    value="replace">
                    {{ $t('project.metadata.modeReplace') }}
                  </v-btn>

                  <v-btn
                    :disabled="!project.canWrite"
                    value="merge">
                    {{ $t('project.metadata.modeMerge') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0">
                <v-card-subtitle>
                  {{value.desc}}
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="$store.state.debugMode">
                  [structure {{value.xmpName}}, type={{value.type}}, propTypeIds={{value.propertyTypeIds}} mode={{value.mode}}]
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0"              v-for="(subValue, subPropId) in value.value"
              :key="subPropId">
              <v-col
                class="my-0 py-0">
                <v-text-field
                  class="mx-4 my-0 pa-0"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  :ref='"input_" + value.id + "_" + subPropId'
                  @input="xmpStructureValueChanged(value, subPropId)"
                  :placeholder="propertyById(subPropId).title"
                  maxLength="1024"
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <!-- array of structure -->
        <v-card
          v-if="value.fields !== null && value.fields.length > 0 && (value.type === 'bag' || value.type === 'seq') && value.uiCategory === activeTab && (hideDisabledValues === false || value.mode !== 'off')"
          class="my-2 mx-0 pa-0">
          <v-container
            class="pa-0 ma-0">
            <v-row
              class="pa-0 ma-0">
              <v-col
                class="pa-0 ma-0">
                <v-card-title
                  class="py-1 text-h6">
                  {{value.title}}
                </v-card-title>
              </v-col>
              <v-col  class="text-right">
                <v-btn-toggle
                        v-model="value.mode"
                        @change="xmpValueModeChanged(value)"
                        :disabled="!project.canWrite"
                        tile
                        dense
                        group
                      >
                        <v-btn
                          :disabled="!project.canWrite"
                          value="off">
                          {{ $t('project.metadata.modeOff') }}
                        </v-btn>

                        <v-btn
                          :disabled="!project.canWrite"
                          value="replace">
                          {{ $t('project.metadata.modeReplace') }}
                        </v-btn>

                      </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0"
              v-for="(value2, position) in value.value"
              :key="value.id + '.' + position">
              <v-col
                class="my-0 py-0 mx-2">
                <v-card>
                  <v-row
                    class="my-0 py-0"
                    v-for="(subValue, subPropId) in value2"
                    :key="subPropId">
                    <v-col
                      class="my-0 py-0">
                      <v-text-field
                        class="mx-4 my-0 pa-0"
                        :disabled="!project.canWrite"
                        :ref='"input_" + value.id + "_" + position + "_" + subPropId'
                        @input="xmpArrayValueChanged(value, position, subPropId)"
                        :placeholder="propertyById(subPropId).title + subPropId"
                        maxLength="1024"
                        dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    class="my-0 py-0">
                    <v-col
                      class="text-right my-0 py-0">
                      <v-btn
                        @click="removeArrayItem(value.id, position)"
                        :disabled="!project.canWrite || value.mode === 'off'"
                        class="ma-2 pa-2"
                        small
                        fab>
                        <v-icon
                          class="mx-4 my-2">
                          mdi-minus-thick
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0">
                <v-card-subtitle>
                  {{value.desc}}
                </v-card-subtitle>
                <v-card-subtitle
                  v-if="$store.state.debugMode">
                  [array_of_structure {{value.xmpName}}, {{value.type}}, propTypeIds={{value.propertyTypeIds}}]
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-row
              class="my-0 py-0">
              <v-col
                class="my-0 py-0 text-right">
                <v-btn
                  @click="addArrayItem(value.id)"
                  :disabled="!project.canWrite || value.mode === 'off'"
                  class="ma-2 pa-2"
                  small
                  fab>
                  <v-icon
                    small
                    class="mx-4 my-2">
                      mdi-plus-thick
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <!-- Overwrite data message box -->
    <MessageBox
      v-model="showOverwriteDataMessageBox"
      title="Overwrite data"
      message="Uploading a xmp file clear all existing meta data values."
      okButtonText="Ok"
      cancelButtonText="Cancel"
      @ok="startUploadXmpFile()"
      @cancel="clearUploadFile()" />

    <!-- Really clear metadate message box -->
    <MessageBox
      v-model="showAskToClearMessageBox"
      title="Clear meta data"
      message="Really clear the whole meta data of this project?"
      okButtonText="Clear"
      cancelButtonText="Cancel"
      @ok="clearMetaData()" />

  </v-container>
</template>

<script>
import properties from "../assets/XmpProperties.json";
import { mapState } from 'vuex'
import MessageBox from './MessageBox'

export default {
  name: 'ProjectOverview',

  mounted() {
    for (var i in this.xmpProperties) {
      var prop = this.xmpProperties[i]

      this.$set(this.values, prop.id, {
        'id': prop.id,
        'title': prop.title,
        'type': prop.type,
        'control': prop.control,
        'title': prop.title,
        'desc': prop.desc,
        'fields': 'fields' in prop ? prop.fields : null,
        'xmpName': prop.xmpName,
        'projectId': this.$route.query.id,
        'propertyTypeIds': '' + prop.id,
        'value': null,
        'mode': 'off',
        'uiCategory': prop.uiCategory,
        'uiOrder': prop.uiOrder,
        'position': 0
      })

      // setup empty structs
      if (this.values[prop.id].type === 'structure') {
        this.$set(this.values[prop.id],
                  'value',
                  new Object())
        for (let idx in this.values[prop.id].fields) {
          let field = this.values[prop.id].fields[idx]
          this.$set(this.values[prop.id].value,
                    field,
                    "");
        }
      }
    }


    var sorted = []
    for (const i in this.values) {
      const val = this.values[i]
      if ('uiOrder' in val
          && typeof val.uiOrder === 'number') {
        sorted.push(val)
      }
    }

    sorted.sort(function (a, b) {
      return (a.uiCategory * 1000 + a.uiOrder) - (b.uiCategory * 1000 + b.uiOrder)
    })

    this.sortedValues = sorted

    this.loadXmpValues()
  },

  beforeDestroy() {
    this.saveOutstanding()
  },

  computed: {
    ...mapState([
      'project'
    ])
  },


  data: () => ({
    chosenFile: null,

    showOverwriteDataMessageBox: false,

    showAskToClearMessageBox: false,

    fileContent: null,

    uploadingFile: false,

    uploadError: null,

    uploadSuccess: false,

    xmpFileAction: {},

    xmpProperties: properties.properties,

    uploadingXmpFile: false,

    values: {
    },

    sortedValues: [
    ],

    mode: null,

    activeTab: 0,

    settingValues: false,

    timeoutByName: {},

    hideDisabledValues: false
  }),

  methods: {
    saveDelayed(name, func) {
      if (name in this.timeoutByName) {
        clearTimeout(this.timeoutByName[name].timeoutId)
      }

      const timeoutId = setTimeout(() => {
        func()
        delete this.timeoutByName[name]
      }, 2000)

      this.timeoutByName[name] = {
        timeoutId: timeoutId,
        func: func
      }
    },

    saveOutstanding() {
      for (const name in this.timeoutByName) {
        clearTimeout(this.timeoutByName[name].timeoutId)
        this.timeoutByName[name].func()
      }
    },

    addDropFile(e) {
      this.chosenFile = e.dataTransfer.files[0]
      this.saveXmpFile()
    },

    setInputValue(name, value) {
      if (!(name in this.$refs)) {
        return
      }
      if (value == null) {
        value = ''
      }
      let element = this.$refs[name][0]
      if (!element) {
        return
      }
      element.internalValue = value
    },

    getInputValue(name) {
      if (!(name in this.$refs)) {
        console.error(name + ' not in $refs')
        return
      }

      let element = this.$refs[name][0]
      return element.internalValue
    },

    actionTitle(type) {
      if (type === 'applyXMPFile') {
        return 'XMP-Datei anwenden'
      } else if (type === 'replaceText') {
        return 'Text ersetzen';
      } else if (type === '') {
        return 'bla';
      }
    },

    logValues() {
      for (var v in this.values) {
        var obj = this.values[v]
        for (var key in obj) {
          console.info('    ' + key + ' => ' + obj[key]);
        }
      }
    },

    propertyById(propId) {
      for (var propIndex in this.xmpProperties) {
        if (this.xmpProperties[propIndex].id === parseInt(propId)) {
          return this.xmpProperties[propIndex];
        }
      }
      console.error('no prop for id ' + propId)
      return null
    },

    actionSubTitle(type) {
      if (type === 'applyXMPFile') {
        return 'Wendet die bereitgestellte XMP-Datie auf das Bild an.'
      }
    },

    valuesForProperty(propId) {
      var res = []
      for (var i in this.values) {
        var val = this.values[i]
        if (val.propertytypeid !== propId) {
          continue;
        }
        res.push(this.values[i])
      }
      return res
    },

    setInputValues() {
      this.settingValues = true
      for (var typeId1 in this.values) {
        if (this.values[typeId1] === null) {
          continue
        }

        // structure
        if (this.values[typeId1].type === 'structure') {
          for (var typeId2 in this.values[typeId1].value) {
            if (!(typeId2 in this.values[typeId1].value)) {
              continue;
            }

            this.setInputValue('input_' + typeId1 + '_' + typeId2, this.values[typeId1].value[typeId2])
          }
          continue
        }

        // array of structures
        if ((this.values[typeId1].type === 'bag'
            || this.values[typeId1].type === 'seq')
            && this.values[typeId1].fields !== null) {

          for (var position in this.values[typeId1].value) {
            for (var typeId2 in this.values[typeId1].value[position]) {
              this.setInputValue('input_' + typeId1 + '_' + position + '_' + typeId2, this.values[typeId1].value[position][typeId2])
            }
          }
          continue
       }

        // sequence and bag
        if (this.values[typeId1].type === 'bag'
            || this.values[typeId1].type === 'seq') {

          // the array element must first be created so postpone setInputValue
          for (var position in this.values[typeId1].value) {
            this.setInputValue('input_' + typeId1 + '_' + position, this.values[typeId1].value[position])
          }
          continue
        }

        // alt
        if (this.values[typeId1].type === 'alt') {
          for (var lang in this.values[typeId1].value) {
            this.setInputValue('input_' + typeId1 + '_' + lang, this.values[typeId1].value[lang])
          }
          continue
        }

        // simple
        if (this.values[typeId1].type  === 'simple') {
          this.setInputValue('input_' + this.values[typeId1].propertyTypeIds, this.values[typeId1].value)
          continue
        }
      }
      this.settingValues = false
    },

    activeTabChanged() {
      setTimeout(() => {
        this.setInputValues()
      })
    },

    loadXmpValues() {
      this.$rest.get('team/project/' + this.$route.query.id + '/xmpvalues')
            .onSuccess((json) => {
              // clear old values
              for (var i in this.values) {
                if (this.values[i].type === 'structure') {
                  for (var j in this.values[i].fields) {
                    const field = this.values[i].fields[j]
                    this.values[i].value[field] = ''
                  }
                } else {
                  this.values[i].value = null
                  this.values[i].mode = 'off'
                }
              }

              // setup modes
              const modes = json['xmpmodes']
              if (modes) {
                for (const i in modes) {
                  var mode = modes[i]
                  this.values[mode.propertytypeid].mode = mode.mode
                }
              }

              // setup values
              const xmpValues = json['xmpvalues']
              for (var i in xmpValues) {
                const val = xmpValues[i]
                const parts = val.propertytypeids.split(',')
                if (parts.length > 2) {
                  console.error("more than two property type ids not supported: " + val.propertytypeids)
                  continue
                }


                // structures
                if (parts.length == 2) {
                  let typeId1 = parts[0];
                  let typeId2 = parts[1];

                  // simple structure
                  if (this.values[typeId1].type === 'structure') {
                    if (this.values[typeId2].type !== 'simple') {
                      console.error('we support only simple types in structures for now');
                      continue;
                    }

                    if (!this.values[typeId1].value) {
                      this.values[typeId1].value = new Object()
                    }

                    this.$set(this.values[typeId1].value,
                              typeId2,
                              val.value)
                    continue
                  }

                  // array of structures
                  if (this.values[typeId1].type === 'bag'
                      || this.values[typeId1].type === 'seq') {
                    if (this.values[typeId2].type !== 'simple') {
                      console.error('simple type expected')
                      continue
                    }
                    if (!(this.values[typeId1].fields.includes(parseInt(typeId2)))) {
                      console.error('property type ' + typeId2 + ' not found in fields of property ' + typeId1)
                      continue
                    }

                    if (!this.values[typeId1].value) {
                      this.values[typeId1].value = new Object()
                    }

                    if (!(val.position in this.values[typeId1].value)) {
                      this.$set(this.values[typeId1].value,
                                                  val.position,
                                                  new Object())
                    }

                    this.$set(this.values[typeId1].value[val.position],
                                                  typeId2,
                                                  val.value)

                    continue
                  }

                  console.error('unsupported property type ' + this.values[typeId].type + ' ids: ' + val.propertyTypeIds)
                  continue
                }

                const typeId = parts[parts.length - 1]
                if (!(typeId in this.values)) {
                  console.error(typeId + ' not in values')
                  continue
                }
                // sequence and bag
                if (this.values[typeId].type === 'bag'
                   || this.values[typeId].type === 'seq') {
                  if (!this.values[typeId].value) {
                    this.values[typeId].value = new Object()
                  }
                  this.$set(this.values[typeId].value,
                            val.position,
                            val.value)
                  continue
                }

                // alt
                if (this.values[typeId].type === 'alt') {
                  if (!this.values[typeId].value) {
                    this.values[typeId].value = new Object()
                  }

                  this.$set(this.values[typeId].value,
                            val.lang,
                            val.value)

                  continue
                }

                if (this.values[typeId].type  === 'simple') {
                  this.values[typeId].value = val.value
                  continue
                }

                console.error('unhandled property type ' + val.propertytypeids)
              }

              setTimeout(() => {
                this.setInputValues()
              })

            })
            .start()
    },

    saveXmpValue(projectId, value) {
      const data = {
        value: value.value
      };

      this.$rest.put('team/project/' + projectId + '/xmpvalue/' + value.propertyTypeIds)
        .data(data)
        .onSuccess((json) => {

        })
        .start()
    },

    saveXmpStructureValue(projectId, value, subPropId) {
      const data = {
        value: value.value[subPropId]
      };
      this.$rest.put('team/project/' + projectId + '/xmpvalue/' + value.propertyTypeIds + ',' + subPropId)
        .data(data)
        .onSuccess((json) => {
        })
        .start()
    },

    saveXmpArrayValue(projectId, value, position, subPropId) {
      if (subPropId === null) {
        let data = {
          'value': value.value[position]
        }
        this.$rest.put('team/project/' + projectId + '/xmpvalue/' + value.propertyTypeIds + '/position/' + position)
          .data(data)
          .onSuccess((json) => {

          })
          .start()
      } else {
        let data = {
          'value': value.value[position][subPropId]
        }
        let propIds = value.id + ',' + subPropId
        this.$rest.put('team/project/' + projectId + '/xmpvalue/' + propIds + '/position/' + position)
          .data(data)
          .onSuccess((json) => {

          })
          .start()
      }
    },

    saveXmpAltValue(projectId, value, lang) {
      let data = {
        'value': value.value[lang]
      }
      this.$rest.put('team/project/' + projectId + '/xmpvalue/' + value.propertyTypeIds + '/lang/' + lang)
        .data(data)
        .onSuccess((json) => {
      })
      .start()
    },

    saveXmpValueMode(propertyTypeId, mode) {
      this.$rest.put('team/project/' + this.$route.query.id + '/xmpvaluemode/' + propertyTypeId + '/mode/' + mode)
        .onSuccess((json) => {
        })
        .start()
    },

    addXmpArrayValue(projectId, propertyTypeIds, position) {
      var data = {
        'properties': []
      }
      let def = this.propertyById(propertyTypeIds)
      if (!('fields' in def)) {
        data.properties.push({
          'id': propertyTypeIds,
          'value': ''
        })
      } else {
        for (var i in def.fields) {
          data.properties.push(
          {
            'id': propertyTypeIds + ',' + def.fields[i],
            'value': ''
          })
        }
      }

      this.$rest.post('team/project/' + projectId + '/xmpvalue/' + propertyTypeIds + '/position/' + position)
        .data(data)
        .onSuccess((json) => {
        })
        .start()
    },

    addXmpAltValue(projectId, propertyTypeId, lang, value) {
      let def = this.propertyById(propertyTypeId)
      const data = {
        value: value
      }
      this.$rest.post('team/project/' + projectId + '/xmpvalue/' + propertyTypeId + '/lang/' + lang)
        .data(data)
        .onSuccess((json) => {
        })
        .start()
    },

    deleteXmpArrayValue(propertyTypeIds, position) {
      this.$rest.delete('team/project/' + this.$route.query.id + '/xmpvalue/' + propertyTypeIds + '/position/' + position)
        .onSuccess((json) => {
        })
        .start()
    },

    deleteXmpAltValue(propertyTypeId, lang) {
      this.$rest.delete('team/project/' + this.$route.query.id + '/xmpvalue/' + propertyTypeId + '/lang/' + lang)
        .onSuccess((json) => {
        })
        .start()
    },

    deleteArrayItem(propertyTypeIds) {
      this.$rest.delete('team/project/' + this.$route.query.id + '/xmpvalue/' + propertyTypeIds)
        .onSuccess((json) => {
        })
        .start()
    },

    xmpValueChanged(xmpValue) {
      if (this.settingValues) {
        return
      }

      const value = this.getInputValue('input_' + xmpValue.id)
      this.values[xmpValue.id].value = value

      const projectId = this.$route.query.id
      const name = 'value_' + xmpValue.id
      this.saveDelayed(name, () => {
        this.saveXmpValue(projectId, xmpValue)
      })
    },

    xmpStructureValueChanged(xmpValue, subPropId) {
      if (this.settingValues) {
        return
      }

      const value = this.getInputValue('input_' + xmpValue.id + '_' + subPropId)
      xmpValue.value[subPropId] = value

      const projectId = this.$route.query.id
      const name = 'struct_value_' + xmpValue.propertyTypeIds + ',' + subPropId
      this.saveDelayed(name, () => {
        this.saveXmpStructureValue(projectId, xmpValue, subPropId)
      })
    },

    xmpArrayValueChanged(xmpValue, position, subPropId) {
      if (this.settingValues) {
        return
      }

      var inputFieldName
      if (!subPropId) {
        inputFieldName = 'input_' + xmpValue.id + '_' + position
      } else {
        inputFieldName = 'input_' + xmpValue.id + '_' + position + '_' + subPropId
      }

      const value = this.getInputValue(inputFieldName)
      if (!subPropId) {
        xmpValue.value[position] = value
      } else {
        xmpValue.value[position][subPropId] = value
      }

      const projectId = this.$route.query.id
      this.saveDelayed(inputFieldName, () => {
        this.saveXmpArrayValue(projectId, xmpValue, position, subPropId)
      });
    },

    xmpAltValueChanged(xmpValue, lang) {
      if (this.settingValues) {
        return
      }

      const inputFieldName = 'input_' + xmpValue.id + '_' + lang

      const value = this.getInputValue(inputFieldName)
      const projectId = this.$route.query.id
      if (!xmpValue.value) {
        xmpValue.value = new Object()
        xmpValue.value[lang] = value
        this.saveDelayed(inputFieldName, () => {
          this.addXmpAltValue(projectId, xmpValue.id, lang, value)
        })
      } else {
        xmpValue.value[lang] = value
        this.saveDelayed(inputFieldName, () => {
          this.saveXmpAltValue(projectId, xmpValue, lang)
        })
      }
    },

    xmpValueModeChanged(xmpValue) {
      if (xmpValue.mode === undefined) {
        return
      }

      this.saveXmpValueMode(xmpValue.id, xmpValue.mode)
    },

    clearUploadFile() {
      this.chosenFile = null
    },

    fileLoaded() {
      this.showOverwriteDataMessageBox = true
    },

    startUploadXmpFile() {
      var str = this.fileContent

      var data = {
        dbprojectid: this.$route.query.id,
        filename: this.chosenFile.name,
        content: str
      }

      this.uploadingXmpFile = true
      this.$rest.command('team/command/storexmpfile')
        .data(data)
        .onSuccess((detail) => {
          this.uploadingXmpFile = false
          this.loadXmpValues()
          this.uploadSuccess = true
          this.uploadError = null
        })
        .onError((detail) => {
          this.uploadingXmpFile = false
          this.loadXmpValues()
          this.uploadSuccess = false
          this.uploadError = detail
        })
        .start()
    },

    saveXmpFile() {
      this.uploadSuccess = false
      this.uploadError = null
      if (!this.chosenFile) {
        this.data = "No File Chosen"
        return
      }

      this.uploadingFile = true
      var reader = new FileReader();
      reader.readAsDataURL(this.chosenFile);
      reader.onload = () => {
        this.fileContent = reader.result;
        this.fileLoaded()
      }
    },

    removeArrayItem(propId, position) {
      this.$delete(this.values[propId].value,
                   position)

      this.deleteXmpArrayValue(propId, position)
    },

    removeAltItem(propId, lang) {
      this.$delete(this.values[propId].value,
                   lang)
      this.deleteXmpAltValue(propId, lang)
    },

    addArrayItem(propId) {
      var lastPosition = 0;

      if (this.values[propId].value === null) {
        this.$set(this.values[propId],
                  'value',
                  new Object())
      }

      for (let i in this.values[propId].value) {
        if (i > lastPosition) {
          lastPosition = i;
        }
      }

      let position = parseInt(lastPosition) + 1

      const projectId = this.$route.query.id
      if (this.values[propId].fields === null) {
        // array of 'simple'
        this.$set(this.values[propId].value,
                  position,
                  "");
        this.addXmpArrayValue(projectId, propId, position)
      } else {
        this.$set(this.values[propId].value,
                  position,
                  new Object());

        for (let idx in this.values[propId].fields) {
          let field =this.values[propId].fields[idx]
          this.$set(this.values[propId].value[position],
                    field,
                    "");
        }
        this.addXmpArrayValue(projectId, propId, position)
      }
    },

    addAltItem(propId) {
      if (!this.values[propId].value) {
        this.values[propId].value = new Object()
      }

      if (this.values[propId].value.length === 1) {
        return;
      }

      this.$set(this.values[propId].value,
                'x-default',
                "");
      this.addXmpAltValue(propId, 'x-default')
    },

    askToClearMetaData() {
      this.showAskToClearMessageBox = true
    },

    clearMetaData() {
      this.$rest.delete('team/project/' + this.$route.query.id + '/xmpvalues')
            .onSuccess((json) => {
              this.loadXmpValues()
            })
            .start()
    },

    downloadPreparedXmp(jobId, token) {
      var ifrm = document.getElementById('downloadFrame');
      var colonAndPort = ''
      if (window.location.port) {
        colonAndPort = ':' + window.location.port
      }
      const url = window.location.protocol + '//' + window.location.hostname + colonAndPort + '/api/' + this.$store.state.team.uuid + '/command/downloadresultfile/' + jobId + '/' + token
      ifrm.src = url
    },

    downloadXmpFile() {
      const postData = {
        'dbprojectid': this.$route.query.id
      }
      this.$rest.command('team/command/downloadxmpfile')
                .data(postData)
                .onSuccess((json, jobId, token) => {
                  this.downloadPreparedXmp(jobId, token)
                })
                .onError((detail) => {
                })
                .start()
    }
  },

  components: {
    MessageBox
  }
}
</script>
