<template>
  <v-container
    fluid>
    <span class="text-h6">Folder {{ folder !== null ? folder.name : '' }}</span>

    <v-divider class="my-2">
    </v-divider>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary">
    </v-progress-circular>
    <br/>

    <v-row>
      <ScriptUpload v-if="folder.type === 'projects'" target="folder" :folderId="this.$route.query.id"></ScriptUpload>
    </v-row>

    <v-row
      v-if="folder.isFolderAdmin">
      <v-col>
        <v-btn
          @click="addUserClicked()"
          class="mx-2"
          fab
          small>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="'permissions' in folder">
      <v-col cols="5">
        <!-- User permissions -->
        <v-data-table
          v-if="folder.isFolderAdmin && 'users' in folder.permissions"
          :headers="userPermissionsHeaders"
          :items="folder.permissions.users"
          :hide-default-footer="true"
          disable-pagination
          sort-by="email"
          item-key="id">
          <!-- remove user button -->
          <template v-slot:item.controls="props">
            <!-- remove user button -->
            <v-btn class="mx-1" small
                @click="removeUserClicked(props.item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

            <!-- edit user button -->
            <v-btn class="mx-1" small
              @click="editUserClicked(props.item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-for="header in userPermissionsHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, item }">
            {{ header.formatter(item) }}
          </template>

        </v-data-table>

      </v-col>
    </v-row>


    <!-- Add group permission button -->
    <v-row
      v-if="folder.isFolderAdmin">
      <v-col>
        <v-btn
          @click="addGroupClicked()"
          class="mx-2"
          fab
          small>
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Group permissions -->
    <v-row
      v-if="folder.isFolderAdmin">
      <v-col cols="5">
        <!-- Group permissions -->
        <v-data-table
          :headers="groupPermissionsHeaders"
          :items="folder.permissions.groups"
          :hide-default-footer="true"
          disable-pagination
          sort-by="email"
          item-key="id">
          <!-- remove group button -->
          <template v-slot:item.controls="props">
            <!-- remove user button -->
            <v-btn class="mx-1" small
                @click="removeGroupClicked(props.item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

            <!-- edit group button -->
            <v-btn class="mx-1" small
              @click="editGroupClicked(props.item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-for="header in groupPermissionsHeaders.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, item }">
            {{ header.formatter(item) }}
          </template>

        </v-data-table>

      </v-col>
    </v-row>



    <ConfirmDialog ref="confirm" />

    <!-- add user or group dialog box -->
    <AddPermissionsDialog
      v-model="addPermissionsDialogVisible"
      @ok="addUserOrGroup($event)"
      :excludeUsers="'permissions' in this.folder ? this.folder.permissions.users.map(user => user.dbuserid) : []"
      :type="this.addPermissionsDialogType"
      :title="this.addPermissionsDialogType=='user' ? 'Add user' : 'Add group'"/>

    <!-- edit user or group dialog box -->
    <edit-user-permissions-dialog
      v-model="editUserPermissionsDialogVisible"
      @ok="updateUserOrGroupPermissions($event)"
      :permissions="this.currentUser ? this.currentUser.permissions : (this.currentGroup ? this.currentGroup.permissions : [])"
      title="Edit user"/>

  </v-container>
</template>

<script>

import AddPermissionsDialog from './AddPermissionsDialog'
import EditUserPermissionsDialog from './EditUserPermissionsDialog.vue'
import ScriptUpload from './ScriptUpload'

export default {
  name: 'Folder',
  components: {
    ConfirmDialog: () => import("./ConfirmDialog"),
    AddPermissionsDialog,
    EditUserPermissionsDialog,
    ScriptUpload
  },

  mounted() {
    if (!!this.$route
        && 'id' in this.$route.query) {
      this.loadFolder(this.$route.query.id)
    }
  },

  data: function () {
    return {
    loading: false,

    currentUser: null,

    currentGroup: null,

    folder: {
    },

    addPermissionsDialogVisible: false,

    addPermissionsDialogType: '',

    editUserPermissionsDialogVisible: false,

    userPermissionsHeaders: [
      {
        text: 'User',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Permissions',
        sortable: true,
        value: 'permissions',
        formatter: this.formatPermissions
      },
      {
        text: '',
        value: "controls",
        sortable: false
      }
    ],

    groupPermissionsHeaders: [
      {
        text: 'Group',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Permissions',
        sortable: true,
        value: 'permissions',
        formatter: this.formatPermissions
      },
      {
        text: '',
        value: "controls",
        sortable: false
      }
    ],
    }
  },

  watch: {
    $route (to, from) {
      if (!!to.query
          && 'id' in to.query) {
        this.loadFolder(to.query.id)
      }
    }
  },

  methods: {
    loadFolder(id) {
      this.loading = true
      this.$rest.get('team/folder/' + id)
                .onSuccess((json) => {
                  var folder = json['folder'][0]
                  console.log(folder)
                  this.folder = folder
                  this.loading = false
                })
                .start()
    },

    addUserClicked() {
      this.addPermissionsDialogType = 'users'
      this.addPermissionsDialogVisible = true
    },

    addGroupClicked() {
      this.addPermissionsDialogType = 'groups'
      this.addPermissionsDialogVisible = true
    },

    removeUserClicked(item) {
      const folderId = this.$route.query.id
      const data = [
      ]
      this.$rest.put('team/folder/' + folderId + '/permission/user/' + item.dbuserid)
        .data(data)
        .onSuccess((json) => {
          this.loadFolder(this.$route.query.id)
        })
        .start()
    },

    removeGroupClicked(item) {
      const folderId = this.$route.query.id
      const data = [
      ]
      this.$rest.put('team/folder/' + folderId + '/permission/group/' + item.dbgroupid)
        .data(data)
        .onSuccess((json) => {
          this.loadFolder(this.$route.query.id)
        })
        .start()
    },

    addUserOrGroup(info) {
      const folderId = this.$route.query.id
      if (this.addPermissionsDialogType === 'users') {
        this.$rest.put('team/folder/' + folderId + '/permission/user/' + info.userId)
          .data(info.permissions)
          .onSuccess((json) => {
            this.loadFolder(this.$route.query.id)
          })
          .start()
      } else {
        this.$rest.put('team/folder/' + folderId + '/permission/group/' + info.groupId)
          .data(info.permissions)
          .onSuccess((json) => {
            this.loadFolder(this.$route.query.id)
          })
          .start()

      }
    },

    updateUserOrGroupPermissions(info) {
      const folderId = this.$route.query.id
      var url;
      if (this.currentUser !== null) {
        url = 'team/folder/' + folderId + '/permission/user/' + this.currentUser.dbuserid
      } else {
        url = 'team/folder/' + folderId + '/permission/group/' + this.currentGroup.dbgroupid
      }
      this.$rest.put(url)
        .data(info.permissions)
        .onSuccess((json) => {
          this.loadFolder(this.$route.query.id)
        })
        .start()
    },

    editUserClicked(user) {
      this.currentUser = user
      this.currentGroup = null
      this.editUserPermissionsDialogVisible = true
    },

    editGroupClicked(group) {
      this.currentGroup = group
      this.currentUser = null
      this.editUserPermissionsDialogVisible = true
    },

    formatPermissions(user) {
      const permissions = user.permissions
      if (permissions.length === 1) {
        return 'read'
      } else if (permissions.length === 2) {
        return 'read/write'
      } else {
        return 'administrate'
      }
      return 'xxx'
    }
  }
}
</script>
