<template>
  <v-container
    fluid>
    <v-data-table
      :headers="headers"
      :items="jobs"
      class="elevation-1"
      :server-items-length="totalJobCount"
      :options.sync="options"
      :loading="loading"
      >
      <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ header, item }">
        {{ header.formatter(item) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'ProjectFTPAccounts',

  mounted() {
  },


  computed: {
    ...mapState([
      'project'
    ])
  },

  watch: {
    options: {
      handler () {
        this.requestPage()
      },
      deep: true,
    },
  },

  data: function () {
    return {
      loading: false,
      options: {},
      totalJobCount: 0,
      headers: [
        {
          text: 'File',
          value: 'filename',
          formatter: this.formatFileName
        },
        {
          text: 'Destination',
          value: 'ftpservername',
          formatter: this.formatDestination
        },
        {
          text: 'Status',
          value: 'state',
          formatter: this.formatState
        },
        {
          text: 'Detail',
          value: 'createtime',
          formatter: this.formatDetail
        },
      ],
      jobs: [
      ]

    }
  },

  methods: {
    requestPage() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const data = {
        'projectid': this.$route.query.id
      };

      this.loading = true
      this.$rest.post('team/jobs/' + itemsPerPage + '/' + (page - 1))
          .data(data)
          .onSuccess((json) => {
            this.jobs = json['jobs']
            this.totalJobCount = json['count']
            this.loading = false
          })
          .start()
    },


    formatFileSize(value) {
      if (value < 1024) {
        return value + 'B'
      }

      return Math.floor(value / 1024) + 'KB'
    },

    formatDestination(value) {
      if (value.ftpservername) {
        return value.ftpservername
      }

      return value.destprojectname
    },

    formatState(value) {
      switch (value.state) {
        case 'scheduled':
          return 'starting ' + this.$utils.formatTimeIntervalFromNow(value.scheduletime)
        case 'running':
          return "running"
        case 'failed':
          return "failed"
        case 'succeeded':
          if (value.trynumber == 0) {
            return 'succeeded'
          }
          return "succeeded (retries: " + value.trynumber + ')'
        default:
          return 'UNKNOWN'
      }
      return value.state
    },

    parseDate(value) {
      // 2022-05-09 11:18:12.269352
      return moment(value, 'YYYY-MM-DD HH:mm:ss')
    },

    formatDetail(value) {
      if (value.state == 'scheduled') {
        var ret = ''
        if (value.errortext) {
          ret = 'last try failed (' + value.errortext + ')'
        } else {
          ret = 'scheduled at' + value.scheduletime
        }
        return ret
      }

      let str = value.createtime
      if (!str) {
        return '';
      }

      let date = this.parseDate(str)
      let today = new moment()
      var out = ''
      if (today.isSame(date, 'day')) {
          out = 'today, ' + date.format('HH:mm:ss')
      } else {
          out = date.format("YYYY-MM-DD HH:mm:ss")
      }

      if (value.state == 'failed') {
        out += ' (' +value.errortext + ')'
        return out
      }


      out += ' (';
      out += this.$utils.formatBytes(value.filesize)

      if (value.uploadduration) {
        const duration = this.$moment.duration(value.uploadduration)
        const seconds = duration.seconds()
        const milliseconds = duration.milliseconds()
        out += ' in ' + seconds + '.' + milliseconds + ' seconds'

        out += ' - '
        out += this.$utils.formatBytes(value.filesize / duration.valueOf() * 1000)
        out += '/second '
      }

      out += ')'
      return out
    },

    formatFileName(item) {
      return item.filename + ' (' + this.formatFileSize(item.filesize) + ')'
    },
  },
}
</script>
