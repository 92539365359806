<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>
    <v-row
      v-if="inProgress"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Processing
        </span>
      </v-col>
    </v-row>
    <v-row
      class="text-center">
      <v-col>
        <v-progress-circular
          v-if="inProgress"
          :size="50"
          color="primary"
          indeterminate
          >
        </v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      v-if="errorMessage">
      <v-col>
        <v-alert
          outlined
          type="warning"
          prominent
          >
          {{errorMessage}}
        </v-alert>
      </v-col>
    </v-row>

    <v-row
      v-if="succeeded">
      <v-col>
        <v-alert
          outlined
          type="success"
          prominent
          >
          Successful. You will be redirected to the login page automatically in some seconds, click <a href="/#/login">here</a> to go there automatically.
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>


import router from '../router'

export default {
  name: 'VerifyEmail',

  mounted() {
    console.log("mounted")
    this.$utils.onAppReady(() => {
      if (this.$store.state.loggedIn) {
        this.$rest.post('user/logout')
        .onSuccess((json) => {
          this.$store.commit("setLoggedOut")
          this.start()
        })
        .start()
        return
      }
      this.start()
    })
  },

  methods: {
    start() {
      if (!('token' in this.$route.query)
          || !('selector' in this.$route.query)) {
        this.errorMessage = 'invalid parameters'
        return
      }

      const data = {
        'token': this.$route.query.token,
        'selector': this.$route.query.selector
      }

      this.inProgress = true
      this.$rest.post('user/verify')
        .data(data)
        .onSuccess((json) => {
          if ('result' in json && json['result'] === 'OK') {
            this.succeeded = true
            setTimeout(() => {
              // go to login page in some seconds
              this.$router.push({ name: 'login' }).catch(() => {})
            }, 9000)
          } else {
            this.errorMessage = 'Something went WRONG!'
          }
          this.inProgress = false
        })
        .onError((json) => {
          this.errorMessage = 'Something went wrong - please try again'
        })
        .start()
    },

  },

  data() {
    return {
      inProgress: false,

      errorMessage: '',

      succeeded: false
    }
  }
};
</script>

<style></style>
