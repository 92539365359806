<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO-<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
        <v-data-table
          :headers="headers"
          :items="summary"
          hide-default-footer
          disable-pagination>
        </v-data-table>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Welcome',

    data: () => ({
      summary: [],

      headers: [
        {
          text: 'Project',
          value: 'projectname'
        },
        {
          text: 'Ftp Server',
          value: 'ftpservername',
        },
        {
          text: 'Jobs',
          value: 'jobcount'
        },
        {
          text: 'Failed',
          value: 'failedjobcount'
        }
      ],
    }),

    mounted() {
      this.$store.dispatch('loadFolders')

      this.$rest.post('team/jobs/summary')
      .onSuccess((json) => {
        this.summary = json['summary']
      })
      .start()
    }
  }
</script>
