<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    width="600">
    <v-card>
      <v-card-title>Edit user</v-card-title>

      <v-divider
        class="pb-6">
      </v-divider>

       <v-form
        @submit.prevent="okClicked()"
        :disabled="inProgress"
        v-model="formValid">
        <v-card-text>
          <v-row>
            <v-col>
              <span>
                Edit Details for user {{user.fullname}}.
              </span>
            </v-col>
          </v-row>

          <v-row>
            <!-- group -->
            <v-col
              class="text-right mt-2"
              cols="3">
              <span>Group</span>
            </v-col>

            <v-col>
              <v-select
                  dense
                  v-model="group"
                  :disabled="user.isOwner"
                  :items="filteredGroups"
                  item-value="dbgroupid"
                  item-text="name">
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <!-- accreditation number -->
            <v-col
              class="text-right mt-2"
              cols="3">
              <span>Accreditation number</span>
            </v-col>

            <v-col>
              <v-text-field
                  dense
                  v-model="user.accreditation_number">
              </v-text-field>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="cancelClicked()"
              >
              Cancel
          </v-btn>

          <v-btn
            text
            type="submit"
            :disabled="!formValid"
            :loading="inProgress">
              Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: false, // show/hide

    user: Object,
  },

  mounted() {

    for (let g in this.groups) {
      if (this.groups[g].name !== 'Owner') {
        this.filteredGroups.push(this.groups[g])
      }
    }
  },

  computed: {
    ...mapState([
      'groups'
    ]),

    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {

      this.groups.forEach(g => {
        if (g.dbgroupid === this.group) {
          this.user.group = g
        }
      });

      // write group
      this.$rest.post('team/user/' + this.user.dbuserid + '/groups')
        .data({
          'groups': [this.user.group.dbgroupid]
        })
        .onSuccess((json) => {
          // write team user details
          console.log('here')
          this.$rest.post('team/teamuser/' + this.user.dbuserid)
            .data({
              'accreditation_number': this.user.accreditation_number
            })
            .onSuccess((json) => {
              this.inProgress = false
              this.show = false
              this.$emit('ok', this.eventItem)
            })
            .start()
        })
        .start()
    }
  },

  watch: {
    user: function (newVal) {
      this.group = newVal.group ? newVal.group.dbgroupid : null
    }
  },

  data() {
    return {
      formValid: true,

      inProgress: false,

      group: null,

      filteredGroups: [],

      rules: {
          required: value => !!value || 'Required.',
          date: value => {
            const ok = this.$moment(value, "DD.MM.YYYY", true).isValid()
            if (!ok) {
              return 'Invalid date'
            }

            return true
          },
          time: value => {
            const ok = this.$moment(value, "HH:mm", true).isValid()
            if (!ok) {
              return 'Invalid time'
            }

            return true
          },

        },
    }
  },
}
</script>