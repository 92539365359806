<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <span class="text-h6">Config files</span>
      </v-col>
    </v-row>
    <v-divider class="my-2">
    </v-divider>
    <v-row>
      <v-col
        class="my-0"
        cols="4"
        v-cloak
        @drop.prevent="addDropFile"
        @dragover.prevent>
          <v-file-input
            ref="fileInput"
            v-model="chosenFile"
            :disabled="uploadingFile"
            :loading="uploadingFile"
            chips
            show-size
            outlined
            prepend-icon="mdi-file-code"
            truncate-length="60"
            accept="XMP-Datei/*.xmp"
            label="Drop file"
            :rules="fileRules"
            @change="addFile()"
            >
          </v-file-input>
      </v-col>
    </v-row>
    <br/>
    <v-data-table
      :headers="headers"
      :items="files"
      item-key="name">
      <template v-slot:item.controls="props">
        <v-btn @click="removeFileClicked(props.item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn @click="downloadFileClicked(props.item)">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="showSnackbar"
      >
      {{this.snackbarText}}
    </v-snackbar>

    <!-- Remote file message box -->
    <MessageBox
      v-model="showRemoveFileMessageBox"
      title="Datei entfernen"
      message="Die Datei wird unwiderruflich entfernt."
      okButtonText="Entfernen"
      cancelButtonText="Abbrechen"
      @ok="removeFile()" />

  </v-container>
</template>

<script>
import InviteUserDialog from './InviteUser'
import EditUserDialog from './EditUserDialog'
import MessageBox from './MessageBox'

export default {
  name: 'ConfigFiles',

  mounted() {
    this.reloadFiles()
  },

  data: () => ({
    showSnackbar: false,

    snackbarText: '',

    showRemoveFileMessageBox: false,

    currentFileId: null,

    chosenFile: null,

    uploadingFile: false,

    fileContent: null,

    files: [
    ],

    fileRules: [
      file => !file || file.size < 100000 || 'File size should be less than 100 KB!',
      file => {
        if (!file) {
          return true
        }

        const parts = file.name.split('.')
        if (parts.length < 2) {
          return 'File must be of type .xmp or .json'
        }

        const ext = parts.pop().toLowerCase()
        if (ext != 'json' && ext !== 'xmp') {
          return 'File must be of type .xmp or .json'
        }

        return true
      }
    ],

    headers: [
        {
          text: 'File',
          value: 'name'
        },
        {
          text: 'Size',
          value: 'size',
          align: 'right'
        },
        {
          text: 'Date',
          value: 'time',
          align: 'right'
        },
        {
          text: '',
          value: 'controls',
          sortable: false
        }
      ],
  }),

  methods: {
    reloadFiles() {
      this.$rest.get('/team/files')
        .onSuccess((json) => {
          this.files = json['files']
        })
        .start()
    },

    realUploadFile() {
      var str = this.fileContent


      var postData = {
        name: this.chosenFile.name,
        content: str
      }

      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].name === this.chosenFile.name) {
          postData['dbconfigfileid'] = this.files[i].dbconfigfileid
          break
        }
      }


      this.$rest.post('team/file')
                .data(postData)
                .onSuccess((json) => {
                  this.uploadingFile = false
                  if ('result' in json && json['result'] === 'OK') {
                    this.showSnackbar = true
                    this.snackbarText = 'File uploaded'
                    const file = {
                      name: this.chosenFile.name,
                      dbconfigfileid: json['dbconfigfileid'],
                      size: json['size'],
                      time: 'now'
                    }

                    this.$utils.removeObjectFromArray(this.files,
                                                      (f) => {
                                                        return f.dbconfigfileid == file.dbconfigfileid;
                                                      });
                    this.files.push(file)
                  } else {
                    this.showSnackbar = true
                    this.snackbarText = 'File upload failed'
                  }
                  this.chosenFile = null
                })
                .start()
      this.uploadingFile = false
    },

    addDropFile(e) {
      this.chosenFile = e.dataTransfer.files[0]
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.addFile()
        })
      })
    },

    addFile() {
      if (!this.chosenFile) {
        this.data = "No File Chosen"
        return
      }

      if (this.$refs.fileInput.hasError) {
        return
      }


      this.uploadingFile = true
      var reader = new FileReader();
      reader.readAsDataURL(this.chosenFile);
      reader.onload = () => {
        this.fileContent = reader.result;
        this.realUploadFile()
      }
    },

    removeFileClicked(file) {
      this.currentFileId = file.dbconfigfileid
      this.showRemoveFileMessageBox = true
    },

    downloadFileClicked(file) {
      var ifrm = document.getElementById('downloadFrame2');
      var colonAndPort = ''
      if (window.location.port) {
        colonAndPort = ':' + window.location.port
      }
      const url = window.location.protocol + '//' + window.location.hostname + colonAndPort + '/api/' + this.$store.state.team.uuid + '/downloadfile/' + file.dbconfigfileid
      ifrm.src = url
    },

    removeFile() {
      this.$rest.delete('/team/file/' + this.currentFileId)
        .onSuccess((json) => {
          if ('result' in json && json['result'] === 'OK') {
            this.$utils.removeObjectFromArray(this.files, (file) => {
              return file.dbimagefileid === this.currentFileId
            })
          }
        })
        .start()
    }
  },

  components: {
    InviteUserDialog,
    MessageBox,
    EditUserDialog
}
}
</script>
