<!--
  Dialog to select an user or a group and permissions to be added to an entity.
-->
<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    width="550">
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-divider
        class="pb-6">
      </v-divider>

      <v-container>
        <v-form
          @submit.prevent="okClicked()"
          v-model="formValid">
          <v-row
            v-if="this.type === 'users'">
            <v-col cols="8">
              <v-autocomplete
                label="User"
                :items="users"
                item-text="email"
                item-value="dbuserid"
                loader-height="3"
                v-model="currentValue"
                :rules="[rules.required]"
                >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row
            v-if="this.type === 'groups'">
            <v-col cols="8">
              <v-autocomplete
                label="Group"
                :items="groups"
                item-text="name"
                item-value="dbgroupid"
                loader-height="3"
                v-model="currentValue"
                :rules="[rules.required]"
                >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-autocomplete
                label="Permission"
                :items="permissions"
                item-text="text"
                item-value="name"
                v-model="currentPermission"
                >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="cancelClicked()"
                >
                Cancel
            </v-btn>

            <v-btn
                text
                type="submit"
                :disabled="!formValid"
                >
                Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: false, // show/hide

    type: '',

    title: '',

    excludeUsers: [
    ]
  },

  mounted() {
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    loadUsers() {
      this.$rest.get('team/users')
          .onSuccess((json) => {
            var users = json['members'].filter((member) => {
              return !this.excludeUsers.includes(member.dbuserid)
                      && member.email_verified // filter invited users
            })

            this.users = users
            console.log(this.users)
          })
          .onError((json) => {
            if (!('error' in json)) {
              return false // show global error dialog
            }

            const error = json['error']['code'];
            if (error === 'MissingPermission') {
              return true // this is ok - continue
            }

            return false
          })

          .start()
    },

    loadGroups() {
      console.log('--- load groups ---')
      this.$rest.get('team/groups')
        .onSuccess((json) => {
          console.log(json)
          var groups = json['groups'].filter((group) => {
            return !this.excludeUsers.includes(group.dbgroupid) && !group.has_all_permissions
          })

          console.log(groups)
          this.groups = groups
        })
        .onError((json) => {
            if (!('error' in json)) {
              return false // show global error dialog
            }

            const error = json['error']['code'];
            if (error === 'MissingPermission') {
              return true // this is ok - continue
            }

            return false
          })
        .start()
    },

    reloadEntities() {
      if (this.type === 'users') {
        this.loadUsers()
      } else if (this.type === 'groups') {
        this.loadGroups()
      }
    },

    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {
      var data = {
        permissions: this.currentPermission
      }
      if (this.type === 'users') {
        data['userId'] = this.currentValue
      } else {
        data['groupId'] = this.currentValue
      }
      console.log(data)
      this.$emit('ok', data)
      this.show = false
    }
  },

  watch: {
    show: {
      handler(val, oldVal) {
        if (val === oldVal) {
          return
        }

        if (!val) {
          return
        }

        this.reloadEntities()
      }
    },
  },

  data() {
    return {
      formValid: false,

      currentValue: null,

      currentPermission: ['read'],

      users: [
      ],

      groups: [
      ],

      rules: {
          required: (value)  => {
           //!!value || 'Required.',
           return value !== null
          }
      },

      permissions: [
        {
          name: ['read'],
          text: 'read only'
        },
        {
          name: ['read', 'write'],
          text: 'read and write'
        },
        {
          name: ['read', 'write', 'folder_admin'],
          text: 'administrate'
        }
      ]
    }
  }
}
</script>
