<template>
  <v-container>
    <v-row class="grow">
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to PHOTO<span style="color: #FF731E">HUB</span>
        </h1>
      </v-col>
    </v-row>

    <v-row
      v-if="invitationMode && !registrationSent"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          You have been invited to join the team {{this.companyName}} on PHOTO-HUB. Please fill in your name and select a password.
        </span>
      </v-col>
    </v-row>

    <v-row
      v-if="!invitationMode && !registrationSent"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Sign up for a free evaluation account. This will only take two minutes.
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="registrationSent"
      class="text-center">
      <v-col class="mb-4">
        <span class="text-body-1 mb-3">
          Success. You should receive an email with a confirmation link in some minutes to complete your registration.
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="showError">
      <v-col>
        <v-alert
          outlined
          type="warning"
          prominent
          >
          {{errorMessage}}
        </v-alert>
      </v-col>
    </v-row>

    <v-form
      v-if="!registrationSent"
      :disabled="inProgress"
      @submit.prevent="signUp()"
      v-model="formValid">

      <v-row class="full-height">
        <v-col offset="4" cols="4">
          <v-card class="justify-center pa-2">
            <v-card-text>
                <!-- first name -->
                <v-text-field
                  name="login"
                  v-model="firstName"
                  label="First name"
                  type="text"
                  autocomplete="given-name"
                  :rules="[rules.required]"
                >
                </v-text-field>

                <!-- last name -->
                <v-text-field
                  name="login"
                  v-model="lastName"
                  label="Last name"
                  type="text"
                  autocomplete="family-name"
                  :rules="[rules.required]"
                >
                </v-text-field>

                <!-- email -->
                <v-text-field
                  name="login"
                  v-model="email"
                  label="Email"
                  type="text"
                  autocomplete="username"
                  :readonly="invitationMode"
                  :disabled="invitationMode"
                  :rules="[rules.required, rules.email]"
                >
                </v-text-field>

                <!-- password -->
                <v-text-field
                  id="password"
                  v-model="password"
                  name="password"
                  label="Password"
                  type="password"
                  autocomplete="new-password"
                  :rules="[rules.required, rules.counter]"
                >
                </v-text-field>

                <!-- password 2 -->
                <v-text-field
                  id="password2"
                  v-model="password2"
                  name="password2"
                  label="Repeat password"
                  type="password"
                  autocomplete="new-password"
                  :rules="[rules.required, rules.counter, rules.password2]"
                >
                </v-text-field>

                <!-- company name -->
                <v-text-field
                  v-if="!invitationMode"
                  name="login"
                  v-model="companyName"
                  label="Company Name"
                  type="text"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="inProgress"
                  :disabled="!formValid"
                  >Sign up</v-btn>
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'Signup',

  mounted() {
    if ('token' in this.$route.query) {
      this.invitationToken = this.$route.query.token
      this.invitationMode = true
      this.companyName = this.$route.query.team
      this.email = this.$route.query.email
    }
  },

  methods: {

    signUp() {
      var data = {
        'email': this.email,
        'firstName': this.firstName,
        'lastName': this.lastName,
        'password': this.password,
        'companyName': this.companyName,
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      if (this.invitationToken) {
        data['invitationToken'] = this.invitationToken
      }

      this.inProgress = true
      this.showError = false
      this.$rest.post('/user/register')
        .data(data)
        .onSuccess((json) => {
          if ('result' in json && json['result'] === 'OK') {
            this.registrationSent = true
          } else {
            this.showError = true
            this.errorMessage = 'Something went WRONG!'
          }
          this.inProgress = false
        })
        .onError((json) => {
                this.inProgress = false
                if (!('error' in json)) {
                  return false // show global error dialog
                }
                this.showError = true
                if (json['error']['code'] === 'UserAlreadyExists') {
                  this.errorMessage = 'The email is already in use.'
                } else if (json['error']['code'] === 'TooManyRequests') {
                  this.errorMessage  = 'Too many requests - please wait some minutes and try again.'
                } else if (json['error']['code'] === 'SendMailFailed') {
                  this.errorMessage = 'Failed to send email - please check your email address and try again.'
                } else {
                  this.errorMessage = 'Something went WRONG!'
                }
                this.inProgress = false
                return true // don't show global error dialog
              })
        .start()
    },
  },

  data() {
    return {
        invitationToken: null,

        invitationMode: false,

        formValid: false,

        firstName: '',

        lastName: '',

        email: '',

        password: '',

        password2: '',

        companyName: '',

        inProgress: false,

        registrationSent: false,

        showError: false,

        errorMessage: '',

        rules: {
          required: value => !!value || 'Required.',

          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },

          counter: value => value.length >= 5 || 'At least 5 characters',

          password2: (value) => {
            if (this.password && value === this.password) {
              return true
            }
            return 'Passwords are not equal'
          }
        }
    }
  }
};
</script>

<style></style>
