<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    width="1000">
    <v-card>
      <v-card-title>{{title}}</v-card-title>

      <v-divider
        class="pb-6">
      </v-divider>

       <v-form
        @submit.prevent="okClicked()"
        v-model="formValid">

        <v-card-text>
          <v-row align="center">
            <!-- category -->
            <v-col
              class="text-right"
              cols="2">
              <span>Category</span>
            </v-col>

            <v-col cols="8">
              <v-overflow-btn
                dense
                :items="eventCategories"
                item-text="title"
                item-value="value"
                v-model="eventItem['category']"
                >
              </v-overflow-btn>
            </v-col>
          </v-row>

          <v-row
            align="center"
            v-if="eventSubCategories.length > 0"
            >
            <!-- subcategory -->
            <v-col
              class="text-right"
              cols="2">
              <span>Subcategory</span>
            </v-col>

            <v-col cols="8">
              <v-overflow-btn
                dense
                :items="eventSubCategories"
                item-text="title"
                item-value="id"
                v-model="eventItem['subcategory']"
                >
              </v-overflow-btn>
            </v-col>
          </v-row>

          <v-row v-if="!showTeams">
            <!-- description -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Event</span>
            </v-col>

            <v-col cols="8">
              <v-text-field
                v-model="eventItem['description']"
                dense>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="showTeams">
            <!-- team1 -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Team 1</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="eventItem['team1']"
                dense>
              </v-text-field>
            </v-col>

            <!-- team2 -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Team 2</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="eventItem['team2']"
                dense>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- summary/scene -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Scene</span>
            </v-col>

            <v-col cols="8">
              <v-combobox
                v-model="eventItem['summary']"
                :items="sceneTitles"
                :rules="[rules.required]"
                dense>
              </v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <!-- date -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Date</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="eventItem['date']"
                :rules="[rules.required, rules.date]"
                hint="dd.mm.yyyy"
                persistent-hint
                dense>
              </v-text-field>
            </v-col>

            <!-- time -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Time</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="eventItem['time']"
                :rules="[rules.required, rules.time]"
                hint="hh:mm"
                persistent-hint
                dense>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- location -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Location</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                  dense
                  v-model="eventItem['location']">
              </v-text-field>
            </v-col>


            <!-- city -->
            <v-col
              class="text-right mt-2"
              cols="2">
              <span>City</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                  dense
                  v-model="eventItem['city']">
              </v-text-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              class="text-right mt-2"
              cols="2">
              <span>State</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                  dense
                  v-model="eventItem['state']">
              </v-text-field>
            </v-col>

            <v-col
              class="text-right mt-2"
              cols="2">
              <span>Country</span>
            </v-col>

            <v-col cols="3">
              <v-text-field
                  dense
                  v-model="eventItem['country']">
              </v-text-field>
            </v-col>

          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="cancelClicked()"
              >
              Cancel
          </v-btn>

          <v-btn
              text
              type="submit"
              :disabled="!formValid"
              >
              Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import events from "../assets/Events.json"

export default {
  props: {
    value: false, // show/hide

    title: '',

    eventItem: {
      time: '',

      date: '2020-01-01',

      summary: '',

      description: '',

      category: '',

      location: '',

      city: '',

      state: '',

      country: ''
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {
      const date = this.$moment(this.eventItem['date'], "DD.MM.YYYY", true)
      this.eventItem['date'] = date.format('YYYY-MM-DD')
      if (this.showTeams) {
        this.eventItem['summary'] = this.eventItem['team1'] + ' - ' + this.eventItem['team2']
      }
      this.$emit('ok', this.eventItem)
      this.show = false
    },

    setEventDefinition(val, json) {
      if ('subCategories' in json) {
        this.eventSubCategories = json['subCategories']

        var index = -1
        for (var i = 0; i < this.eventSubCategories.length; i++) {
          if (this.eventSubCategories[i].id === val['subcategory']) {
            index = i
            break
          }
        }

        if (index !== -1) {
          this.sceneTitles = this.eventSubCategories[index].scenes
          if (!('showTeams' in this.eventSubCategories[index])
              || this.eventSubCategories[index].showTeams) {
            this.showTeams = true
          } else {
            this.showTeams = false
          }
        }
      } else {
        this.eventSubCategories = []
        this.sceneTitles = json['scenes']
        this.showTeams = false
      }
    }
  },

  watch: {
    eventItem: {
      handler(val) {
        // eventItem has changed - load the config file
        this.$rest.get('team/file/' + val['category'] + '.json')
          .onSuccess((json) => {
            this.setEventDefinition(val,  JSON.parse(json['file']['content']))
          })
          .start()
      },
      deep: true
    }
  },

  data() {
    return {
      formValid: false,

      eventCategories: events,

      eventSubCategories: [],

      showTeams: true,

      sceneTitles: [""],

      rules: {
          required: value => !!value || 'Required.',

          date: value => {
            const ok = this.$moment(value, "DD.MM.YYYY", true).isValid()
            if (!ok) {
              return 'Invalid date - expecting date in dd.mm.yyyy format'
            }

            return true
          },

          time: value => {
            const ok = this.$moment(value, "HH:mm", true).isValid()
            if (!ok) {
              return 'Invalid time - expecting time in hh:mm format'
            }

            return true
          },


        },
    }
  },
}
</script>