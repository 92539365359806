import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'
import vuetify from './plugins/vuetify'
import {rest} from './rest.js'
import {utils} from './utils.js'
import $ from 'jquery'
import store from "./store";
import moment from 'moment'

window.$ = $
Vue.config.productionTip = false
Vue.prototype.$rest = rest
Vue.prototype.$utils = utils
Vue.prototype.$moment = moment

Vue.use(VueI18n)

const messages = {
  en: {
    project: {
      metadata: {
        modeOff: 'Off',
        modeReplace: 'Replace',
        modeAppend: 'Append',
        modePrepend: 'Prepend',
        modeMerge: 'Merge',
        dropXmpFile: 'Drop or click to add upload xmp file'
      }
    }
  },

  de: {
    project: {
      metadata: {
        modeOff: 'Aus',
        modeReplace: 'Ersetzen',
        modeAppend: 'Anfügen',
        modePrepend: 'Voranstellen',
        modeMerge: 'Zusammenführen'
      }
    }
  }
}


const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})

window.vueobject = new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
})

window.vueobject.$mount('#app')
