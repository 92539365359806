<template>
  <v-container>
    <v-row class="pb-8">
    </v-row>
    <v-row>
      <v-col
        cols="1">
      </v-col>
      <v-col
        cols="4"
        class="mb-4">
        <span class="text-h3 font-weight-bold mb-3">
          PHOTO<span style="color: #FF731E">HUB</span> is the easy and fast way to transfer your photos to your clients
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="1">
      </v-col>
      <v-col
        cols="4"
        class="mb-4">
        <span class="text-h5">
          <ul>
            <li class="pb-2">Distribute your photos via FTP to multiple clients in one step</li>
            <li class="pb-2">Update your photo's meta data like xmp or exif automatically</li>
            <li>It is just good</li>
          </ul>
        </span>
      </v-col>
    </v-row>
    <v-row class="grow">
    </v-row>
  </v-container>
</template>

<script>

import router from '../router'

export default {
  name: 'Login',

  data: () => ({
    username: '',

    password: '',

    invalidlogin: false,

    emailNotVerified: false,

    inProgress: false
  }),

  methods: {
    recoverPassword() {
      router.push('/recover').catch(() => {})
    },

    login() {
      const data = {
        email: this.username,
        password: this.password,
        rememberme: true
      }

      this.inProgress = true
      this.invalidlogin = false
      this.emailNotVerified = false
      this.$rest.post('user/login')
              .data(data)
              .onSuccess((json) => {
                this.inProgress = false

                if ('logged_in' in json && json['logged_in'] === true) {
                  this.$store.dispatch('updateUser')
                } else if ('error' in json && json['error']['code'] === 'EmailNotVerified') {
                    this.emailNotVerified = true
                } else {
                  this.invalidlogin = true
                }
              })
              .start()
    },
  }
};
</script>

<style></style>
