<template>
  <v-container>
    <v-row>
      <v-col cols>

        <!-- Vcard with ftp destination selection controls -->
        <v-card
          max-width="600">
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  FTP Server destinations
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-wrap">
                  Select the ftp-servers to which your files should be transferred.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Autocomplete to add ftp servers -->
            <v-list-item
              v-if="project.otherFtpServers.length > 0">
              <v-autocomplete
                label="Add ftp server"
                class="mt-6 px-4 py-0"
                filled
                rounded
                solo
                dense
                :disabled="!project.canWrite"
                item-text="name"
                item-value="id"
                return-object
                :items="project.otherFtpServers"
                v-model="selectedFTPServer"
                :search-input.sync="searchStringDestinationFtpServer"
                v-on:change="onAddFTPServer()">
              </v-autocomplete>
            </v-list-item>

            <!-- Hint that there are no ftp servers available -->
            <v-list-item
              v-if="project.ftpServers.length + project.otherFtpServers.length === 0">
              There are no ftp servers yet.
            </v-list-item>

            <!-- Table showing selected ftp servers -->
            <v-list-item
              v-if="project.ftpServers.length > 0">
              <v-data-table
                :headers="headers"
                :items="project.ftpServers"
                :hide-default-footer="true"
                disable-pagination
                sort-by="name"
                item-key="id">
                <template v-slot:item.path="props">
                  <v-edit-dialog
                    v-if="project.canWrite"
                    :disabled="!project.canWrite"
                    :return-value.sync="props.item.path"
                    @save="savePath(props.item.id, props.item.path)"
                    @cancel="cancelPath"
                    >
                    {{ props.item.path }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.path"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.controls="props">
                  <v-btn
                    icon
                    v-if="project.canWrite"
                    :disabled="!project.canWrite"
                    @click="onRemoveFTPServer(props.item.id)">
                      <v-icon color="grey lighten-1">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-list-item>
          </v-list>
        </v-card>

        <!-- Vcard with project destination selection controls -->
        <v-card
          max-width="600"
          class="mt-4">
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  Project destinations
                </v-list-item-title>
                <v-list-item-subtitle
                  class="text-wrap">
                  Select the projects to which your files should be transferred.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Autocomplete list with available destination projects -->
            <v-list-item
              v-if="project.otherProjects.length > 0">
              <v-row>
                <v-col cols="4">
                  <v-autocomplete
                    label="Add destination project"
                    class="mt-6"
                    filled
                    rounded
                    solo
                    dense
                    :disabled="!project.canWrite"
                    item-text="name"
                    item-value="id"
                    return-object
                    :items="project.otherProjects"
                    v-model="selectedDestinationProject"
                    :search-input.sync="searchStringDestinationProject"
                    v-on:change="onAddDestinationProject()">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-list-item>

            <!-- Info text if there are no destination projects available -->
            <v-list-item
              v-if="project.destinationProjects.length + project.otherProjects.length === 0">>
              There are no destination projects yet.
            </v-list-item>

            <!-- Table with selected destination projects -->
            <v-list-item>
                <v-card
                  max-width="500"
                  v-if="project.destinationProjects.length > 0">
                  <v-data-table
                    :headers="headers2"
                    :items="project.destinationProjects"
                    :hide-default-footer="true"
                    disable-pagination
                    sort-by="name"
                    item-key="id">
                    <template v-slot:item.controls="props">
                      <v-btn
                        icon
                        v-if="project.canWrite"
                        :disabled="!project.canWrite"
                        @click="onRemoveDestinationProject(props.item.id)">
                          <v-icon color="grey lighten-1">mdi-close-circle-outline</v-icon>
                      </v-btn>
                  </template>
                  </v-data-table>
                </v-card>
                </v-list-item>


          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ProjectDestinations',

  computed: {
    ...mapState([
      'project'
    ])
  },

  methods: {
    onAddFTPServer(server) {
      this.$store.dispatch('addFtpServerToProject', this.selectedFTPServer.id)
      this.$nextTick(() => {
          this.selectedFTPServer = null;
          this.searchStringDestinationFtpServer = '';
      });
    },

    onRemoveFTPServer(serverId) {
      this.$store.dispatch('removeFtpServerFromProject', serverId)
    },

    onAddDestinationProject(project) {
      this.$store.dispatch('addDestinationProjectToProject', this.selectedDestinationProject.id)
      this.$nextTick(() => {
          this.selectedDestinationProject = null;
          this.searchStringDestinationProject = '';
      });
    },

    onRemoveDestinationProject(projectId) {
      this.$store.dispatch('removeDestinationProjectFromProject', projectId)
    },

    savePath(ftpServerId, newPath) {
      const value = {
        'dbftpserverid': ftpServerId,
        'path': newPath
      }
      this.$store.dispatch('setProjectFtpServerPath', value)
    },

    cancelPath() {

    }
  },

  data: () => ({
    selectedFTPServer: null,

    selectedDestinationProject: null,

    searchStringDestinationFtpServer: '',

    searchStringDestinationProject: '',

    headers: [
      {
        text: 'Server',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Path',
        sortable: false,
        value: 'path'
      },
      {
        text: '',
        sortable: false,
        value: 'controls'
      }
    ],
    headers2: [
      {
        text: 'Destinations',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: '',
        sortable: false,
        value: 'controls'
      }
    ]
  })
}
</script>
