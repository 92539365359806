<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="show"
    max-width="600px">
    <v-card>
      <v-card-title>Team wechseln</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="pt-4">Wechseln Sie das aktuelle Team.</div>
      </v-card-text>

      <v-form v-model="formValid">
        <v-container>
          <v-row class="my-0 py-0">
            <v-col>
              <v-select
                v-model="newteam"
                :items="items"
                label="Teams"
                outlined
                dense
                >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions class="justify-end">
        <v-btn
            text
            @click="cancelClicked()"
            >
            Abbrechen
        </v-btn>

        <v-btn
            text
            @click="okClicked()"
            :disabled="!newteam"
            >
            Wechseln
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean, // show/hide
    title: String,
    message: String,
    cancelButtonText: String,
    okButtonText: String,
    editLabelText: String,
    initialEditText: String
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    showEdit: {
        get() {
            return this.initialEditText
                    || this.editLabelText

        }
    },

    ...mapState([
      'teams',
      'team'
    ]),

  },

  methods: {
    cancelClicked() {
      this.$emit('cancel')
      this.show = false
    },

    okClicked() {
      this.$emit('ok', this.editText)
      this.show = false
    },

    updateItems() {
      this.items = []
      this.teams.forEach((item, index) => {
        if (item.teamid !== this.team.uuid) {
          this.items.push({
            'text': item.name,
            'value': item.teamid
          })
        }
      })
    }
  },

  watch: {
    initialEditText: function(newVal) {
      this.editText = newVal
    },

    value: function(newVal) {
      if (newVal) {
        this.updateItems()
      }
    }
  },

  data() {
    return {
      editText: this.initialEditText,
      formValid: false,
      newteam: '',
      items: [
        {
          'text': 'TestTeam',
          'value': '1'
        }
      ]
    }
  },
}
</script>