<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <span class="text-h6">User Management</span>
      </v-col>
      <v-col>
        <v-btn
          depressed
          @click="showInviteUserDialog=true"
          >
          Add User
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-2">
    </v-divider>

    <br/>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Account
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Gruppen
              </th>
              <th class="text-left">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in users"
              :key="user.id">
              <td>{{ user.email }}</td>
              <td>
                {{ user.email_verified ? user.fullname : 'Invitation pending' }}
                <v-chip
                  v-if="user.accreditation_number"
                  color="orange lighten-2"
                  class="mx-0 black--text"
                  label
                  small
                  >
                  {{user.accreditation_number}}
                </v-chip>
              </td>
              <td>{{ user.group ? user.group.name : 'No Group' }}</td>
              <td>
                <!-- edit user button -->
                <v-btn
                  icon
                  @click="editUserClicked(user)"
                  color="green lighten-1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <!-- delete user button -->
                <v-btn
                  icon
                  v-if="!user.isOwner"
                  @click="removeUserClicked(user)"
                  color="red">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

                <!-- accept invitation button (debug mode only) -->
                <v-btn
                  icon
                  v-if="$store.state.debugMode && !user.email_verified"
                  @click="acceptInvitationClicked(user)"
                  color="blue">
                  <v-icon>mdi-alert-plus</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-snackbar
        v-model="showSnackbar"
        >
        {{this.snackbarText}}
     </v-snackbar>

    <!-- Invite user dialog -->
    <InviteUserDialog
        v-model="showInviteUserDialog"
        @alreadyok="snackbarText = 'User already in team.';  showSnackbar = true"
        @addedok="snackbarText = 'User added to team'; showSnackbar = true; reloadUsers()"
        @invitationsent="snackbarText = 'An invitation was sent'; showSnackbar = true; reloadUsers()"
        @error="snackbarText='Something went wrong.'; showSnackbar = true"
       />

    <!-- Remote user message box -->
    <MessageBox
      v-model="showRemoveUserMessageBox"
      title="Benutzer entfernen"
      message="Der Benutzer wird aus dem Projekt entfernt."
      okButtonText="Entfernen"
      cancelButtonText="Abbrechen"
      @ok="removeUser()" />

    <!-- Edit user dialog -->
    <EditUserDialog
      v-model="showEditUserDialog"
      :user="currentUser" />

  </v-container>
</template>

<script>
import InviteUserDialog from './InviteUser'
import EditUserDialog from './EditUserDialog'
import MessageBox from './MessageBox'

export default {
  name: 'Users',

  mounted() {
    this.reloadUsers()
  },

  data: () => ({
    users: [
    ],

    currentUser: {},

    showInviteUserDialog: false,

    showRemoveUserMessageBox: false,

    showEditUserDialog: false,

    showSnackbar: false,

    snackbarText: ''
  }),

  methods: {
    reloadUsers() {
      this.users = []
      this.$rest.get('team/users')
          .onSuccess((json) => {
            let members = json['members']
            this.users = []
            if (members) {
              members.forEach((member) => {
                let user = {
                  'dbuserid': member.dbuserid,
                  'email': member.email,
                  'fullname': member.firstname + ' ' + member.lastname,
                  'firstname': member.firstname,
                  'lastname': member.lastname,
                  'group': member.groups[0],
                  'isOwner': false,
                  'accreditation_number': member.accreditation_number,
                  'email_verified': member.email_verified
                }

                for (let i = 0; i < member.groups.length; i++) {
                  if (member.groups[i].name == 'Owner') {
                    user.isOwner = true
                    break
                  }
                }
                this.users.push(user)
              });
            }
          })
          .start()
    },

    removeUserClicked(user) {
      this.currentUser = user
      this.showRemoveUserMessageBox = true
    },

    editUserClicked(user) {
      this.currentUser = user
      this.showEditUserDialog = true
    },

    acceptInvitationClicked(user) {
      const data = {
        email: user.email,
        firstName: user.email.split('@')[0],
        lastName: 'Doe',
        password: '12345',
        timezone: 'UTC'
      }
      this.$rest.post('/admin/user/acceptInvite')
        .data(data)
        .onSuccess((json) => {
          this.reloadUsers()
        })
        .start()

    },

    removeUser() {
      const data = {
        email: this.currentUser.email
      }
      this.$rest.delete('/team/user')
        .data(data)
        .onSuccess((json) => {
          this.reloadUsers()
        })
        .start()
    }
  },

  components: {
    InviteUserDialog,
    MessageBox,
    EditUserDialog
}
}
</script>
