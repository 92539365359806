<template>
  <v-container
    fluid>
    <v-row>
      <v-col
        cols="3">
        <v-alert
          v-if="uploadError !== null"
          dismissible
          type="error">
          Something went wrong: <br> {{uploadErrorPosition}} <br> {{uploadError}}
        </v-alert>
        <v-alert
          v-if="uploadSuccess"
          dismissible
          type="success">
          Script upload finished.
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="my-0"
        cols="4"
        v-cloak
        @drop.prevent="addDropFile"
        @dragover.prevent>
          <!-- /v-file-input>:disabled="!project.canWrite" -->
        <v-file-input
          v-model="chosenFile"
          :loading="uploadingFile"
          chips
          show-size
          outlined
          prepend-icon="mdi-file-code"
          truncate-length="60"
          accept="Script File/*.script"
          label="Select script file"
          @change="saveScriptFile()"
          @click:clear="clearScriptClicked()"
          >
        </v-file-input>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          @click="downloadScript()">
          Download script
        </v-btn>
      </v-col>
    </v-row>
    <!-- remove script message box -->
    <MessageBox
      v-model="showClearScriptMessageBox"
      title="Remove script"
      message="Do you really want to remove the script from this project?"
      okButtonText="Remove"
      cancelButtonText="Cancel"
      @ok="clearScript()" />
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import properties from "../assets/XmpProperties.json";
import MessageBox from './MessageBox'
import { mapState } from 'vuex'

export default {
  props: {
    target: String, // project | folder
    projectId: String,
    folderId: String
  },

  components: {
    draggable,
  },

  mounted() {
    this.loadScript()
  },

  name: 'ScriptUpload',

  data: () => ({
    chosenFile: null,

    fileContent: null,

    uploadingFile: false,

    uploadError: null,

    uploadErrorPosition: null,

    uploadSuccess: false,

    scriptFileAction: {},

    showClearScriptMessageBox: false,
  }),

  methods: {

    addDropFile(e) {
      this.chosenFile = e.dataTransfer.files[0]
      this.saveScriptFile()
    },

    fileLoaded() {
      var str = this.fileContent

      var postData = {
        filename: this.chosenFile.name,
        content: str
      }

      if (this.target === 'project') {
        postData.dbprojectid = this.projectId
      } else if (this.target === 'folder') {
        postData.dbfolderid = this.folderId
      }


      this.$rest.command('team/command/storescriptfile')
                .data(postData)
                .onSuccess((json) => {
                  this.uploadingFile = false
                  this.uploadSuccess = true
                  this.uploadErrorPosition = null
                  this.uploadError = null
                })
                .onError((detail) => {
                  this.uploadingFile = false
                  this.uploadSuccess = false
                  const parts = detail.split('<||||>')
                  if (parts.length === 2) {
                    this.uploadErrorPosition = parts[0]
                    this.uploadError = parts[1]
                  } else {
                    this.uploadErrorPosition = null
                    this.uploadError = detail
                  }
                  this.resetFile()
                })
                .start()
      this.uploadingFile = true
    },

    resetFile() {
        this.data = "No File Chosen"
        this.chosenFile = null
    },

    loadScript() {
      var url;
      if (this.target === 'project') {
        console.log('target is ' + this.target)
        url = 'team/project/' + this.projectId + '/script'
      } else if (this.target === 'folder') {
        url = 'team/folder/' + this.folderId + '/script'
      }

      this.$rest.get(url)
            .onSuccess((json) => {
              if (json['script'] === null
                  || json['script'].length === 0) {
                this.chosenFile = null
              } else {
                var script = json['script'][0]
                var blob = script['source']
                const bytes = new Uint8Array(blob.length);
                for (let i = 0; i < blob.length; i++) {
                  bytes[i] = blob.charCodeAt(i);
                }
                this.chosenFile = new File([bytes],
                              script['filename'], {
                              type: "text/plain",
                              })
              }

            })
            .start()
    },

    async downloadScript() {
      var element = document.createElement('a');
      var text = await this.chosenFile.text()
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', this.chosenFile.name)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },

    clearScriptClicked() {
      let save = this.chosenFile
      this.showClearScriptMessageBox = true
      this.$nextTick(() => {
          this.chosenFile = save
      })
    },

    clearScript() {
      var url;
      if (this.target === 'project') {
        url = 'team/project/' + this.projectId + '/script'
      } else if (this.target === 'folder') {
        url = 'team/folder/' + this.folderId + '/script'
      }

      this.$rest.delete(url)
        .onSuccess((json) => {
          if (json['result'] == 'OK') {
            this.chosenFile = null
          }
        })
        .start()
    },

    saveScriptFile() {
      this.uploadSuccess = false
      this.uploadError = null
      this.uploadErrorPosition = null
      if (!this.chosenFile) {
        this.data = "No file Chosen"
        return
      }

      this.uploadingFile = true
      var reader = new FileReader();
      reader.readAsDataURL(this.chosenFile);
      reader.onload = () => {
        this.fileContent = reader.result;
        this.fileLoaded()
      }
    },
  },

  components: {
    MessageBox
  }


}
</script>
